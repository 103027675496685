import './OrganisationPicker.scoped.scss'
import Node from './Node'
import ToggleNode from 'Shared/components/ToggleNode'
import Loading from 'Shared/components/Loading/Loading'
import restClient from 'Shared/hooks/restClient'

const TREE_URL = '/api/organisations/tree'
const SEARCH_URL = '/api/organisations/search'

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)


function getRootOrgs() {
  return restClient.get(TREE_URL).then((r) => r.data)
}

function getChildren(orgId, externalType) {
  return restClient.get(`${TREE_URL}/${orgId}?external_type=${externalType || ''}`).then((r) => r.data)
}

function formatSearchResult(org) {
  return {
    id: org.value,
    parentNames: org.parentNames,
    name: org.label.replace(/\s+\(.*\)$/, ''),
    externalType: org.externalType,
    hasChildren: false,
  }
}

function searchOrgs(query) {
  return restClient.get(`${SEARCH_URL}?query=${encodeURIComponent(query)}`)
    .then((r) => r.data.map(formatSearchResult))
}


function OrgNode({ 
  name, id, depth = 0, onClick, hasChildOrgs = false, 
  externalTypes,  // loaded along with root node
  externalType, // external type if this is a child node
  isExternalType = false, // is this a fake org representing an external type
  parentNames, // for search results only
  ...props }
) {
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [childOrgs, setChildOrgs] = useState(null)
  const [hasMore, setHasMore] = useState(false)


  const toggleNode = () => {
    setExpanded(!expanded)

    // If passed externalTypes which are loaded along with the root node
    if(externalTypes) {
      // Create fake orgs to represent the external types rather than loading real child orgs
      setChildOrgs(externalTypes.map((type) => (
        {
          id: `${id}-${type}`,
          name: capitalize(type),
          hasChildren: true,
          isExternalType: true,

        }
      )))
      return
    }

    setLoading(true)
    if (childOrgs) return

    // External type node acts as a root node but includes the type in the id for use when filtering children
    let type = null;
    if(isExternalType) {
      type = id.split('-')[1]
    }

    getChildren(id, type).then((data) => {
      setLoading(false)
      setChildOrgs(data.organisations)
      setHasMore(data.hasMore)
    })
  }

  // Immediately load first level of children
  useEffect(() => {
    if (depth === 0 && hasChildOrgs) {
      toggleNode()
    }
  }, [])

  return (
    <Node
      name={name}
      parentNames={parentNames}
      depth={depth}
      expandable={depth > 0 && hasChildOrgs}
      expanded={expanded}
      label={externalType}
      toggle={toggleNode}
      onClick={() => onClick(id, name)}
    >

      {childOrgs && expanded &&
        <div className="org-children">
          {childOrgs.map(({ name, id, hasChildren, externalType, isExternalType }) => (
            <OrgNode 
              depth={depth + 1} 
              name={name} id={id} 
              onClick={onClick} 
              hasChildOrgs={hasChildren} 
              isExternalType={isExternalType}
              externalType={externalType}
              key={id} />
          ))}
          {hasMore &&
            <Node 
              name="More organisations exist but were not loaded"
              depth={depth + 1}
              expandable={false}
              textOnly={true}
            />
          }
        </div>
      }

    </Node>
  )
}


export default function OrganisationPicker({ onPick }) {
  const [loading, setLoading] = useState(true)
  const [rootOrgs, setRootOrgs] = useState(null)
  const [externalTypes, setExternalTypes] = useState(null)
  const [searchResultOrgs, setSearchResultOrgs] = React.useState(null)
  const searchInputRef = React.useRef(null)

  let displayOrgs = null
  if (rootOrgs && !searchResultOrgs) {
    displayOrgs = rootOrgs
  } else if (searchResultOrgs && searchResultOrgs.length > 0) {
    displayOrgs = searchResultOrgs
  }

  useEffect(() => {
    getRootOrgs().then((data) => {
      setRootOrgs(data.organisations)
      setExternalTypes(data.externalTypes || [])
      setLoading(false)
    })
  }, [])


  const search = (value) => {
    if (value === '') {
      setSearchResultOrgs(null)
      return
    }
    setLoading(true)
    searchOrgs(value).then((orgs) => {
      setLoading(false)
      setSearchResultOrgs(orgs)
    })
  }
  const debouncedSearch = _.debounce(search, 500)

  return (
    <div className="organisation-picker">
      <div>
        <input type="search" ref={searchInputRef} placeholder="Search&hellip;" className="full-width" onChange={(e) => debouncedSearch(e.target.value)} />
      </div>

      <div className="orgs">
        {loading && <Loading />}

        {!loading && searchResultOrgs && searchResultOrgs.length === 0 && <div>No matching organisations found</div>}

        {!loading && displayOrgs && displayOrgs.map(({ name, id, hasChildren, parentNames, externalType }) => (
          <OrgNode 
            name={name} 
            id={id} 
            onClick={onPick} 
            hasChildOrgs={hasChildren} 
            key={id} 
            externalTypes={externalTypes} 
            externalType={externalType}
            parentNames={parentNames}
          />
        ))}
      </div>
    </div>
  )
}

