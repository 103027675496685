import { useAct } from 'FlowBuilder/store'

export default function FieldEdit({ slug }) {
  const act = useAct()
  const field = useSel(s => s.builder.flow.template.fields.find(f => f.slug === _.snakeCase(slug)))
  const value = useSel(s => s.builder.templateFields[slug])

  const styles = {
    fontSize: '14px',
    cursor: 'pointer',
    position: 'absolute',
    top: '-12px',
    right: '-2px',
    width: '16px',
    height: '16px'
  }
  const wrapperStyles = {
    border: '1px solid #ccc',
    borderRadius: '3px',
    padding: '0px 5px',
    position: 'relative'
  }

  function handleClick() {
    act.local.setModal({ kind: 'editContent', slug })
  }

  return <>
    {field.wysiwyg && <div style={wrapperStyles}>
      <div dangerouslySetInnerHTML={{ __html: value }} />
      <span style={styles} onClick={handleClick}><SvgIconsEdit color={'black'} /></span>
    </div>}
    {!field.wysiwyg && !field.image && <span style={wrapperStyles}>{value} <span style={styles} onClick={handleClick}><SvgIconsEdit color={'black'} /></span></span>}
    { field.image && <span style={wrapperStyles}><img src={value} alt={field.label} /> <span style={styles} onClick={handleClick}><SvgIconsEdit color={'black'} /></span></span> }
  </>
}
