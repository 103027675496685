import ShareForm from './ShareForm'
import TemplateRender from '../TemplateRender'
import Preview from '../Preview'
import FieldEdit from '../FieldEdit'

export default function SurveyActionView({ actionId }) {
  const templateFields = useSel(s => s.builder.flow.templateFields)
  const flowFields = useSel(s => s.builder.flow.flowFields)
  const publicUrl = useSel(s => s.builder.flow.publicUrl)
  const context = { template: templateFields, flow: { ...flowFields, currentActionKind: 'share' } }
  const { body, head, styles } = useSel(s => s.builder.flow.template)
  const action = useSel(s => s.actions.entities[actionId] || {})

  const componentMap = useMemo(() => ({
    TakeSurveyForm: <>
      <ShareForm
        action={action}
        publicUrl={publicUrl}
        onSubmit={() => Promise.resolve()}
      />
    </>,
    FieldEdit,
  }), [action.config])

  return <>
    <Preview styles={styles} head={head}>
      <TemplateRender htmlContent={body} componentMap={componentMap} context={context} edit />
    </Preview>
  </>
}
