import './Preview.scoped.scss'

export default function Preview ({ children, styles, head }) {
  const iframeRef = useRef(null);
  const [iframeBody, setIframeBody] = useState(null);
  const mobile = useSel((s) => s.local.mobile)

  function getPreviewRoot() {
    const iframe = iframeRef.current;
    const iframeDoc = iframe.contentDocument;

    const root = iframeDoc.getElementById('preview-root');

    if (root) {
      setIframeBody(root);
    } else {
      setTimeout(() => {
        getPreviewRoot();
      }, 100);
    }
  }

  useEffect(() => {
    const iframe = iframeRef.current;
    const iframeDoc = iframe.contentDocument;

    // Setup the iframe document
    iframeDoc.open();
    iframeDoc.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0">
          <meta charset="UTF-8">
          <title>Preview</title>
          <style>
            ${styles}
          </style>
          ${head}
        </head>
        <body id="preview-root">
        </body>
      </html>
    `);
    iframeDoc.close();

    getPreviewRoot()
  }, []);

  return (
    <div className={mobile ? 'outer mobile' : 'outer desktop'}>
      <div className='mobile-wrapper'>
        <iframe
          ref={iframeRef}
          className='preview' 
        />
        {iframeBody && ReactDOM.createPortal(
          <>
            {React.Children.map(children, child => child)}
          </>,
          iframeBody
        )}
      </div>
    </div>
  );
};
