import { renderToString } from 'react-dom/server'

const columns = [
  {
    data: 'name',
    className: 'bold',
    width: '37%',
    render: function (data, type, row, meta) {
      var ellipsisClass = row['status'] === 'draft' ? 'accent' : 'active'
      return `
        <a href='${row['url']}'>${data}</a>
      `
    },
  },
  {
    data: 'type',
    className: 'light',
    width: '18%',
    render: function (data, type, row, meta) {
      if (row['secondary_type']) {
        return `<span>${row['primary_type']}</span><span class='ellipsis'></span><span>${row['secondary_type']}</span>`
      } else {
        return row['primary_type']
      }
    },
  },
  {
    data: 'created_by', width: '20%'
  },
  {
    data: 'organisation', width: '18%'
  },
  {
    data: 'id',
    width: '7%',
    className: 'center',
    render: (data, type, row) => {
      return `
        <button class="button secondary small approve-campaign-button" data-campaign-id="${data}" data-scheduled-for="${row['scheduled_for']}">Approve</button>&nbsp;
        <button class="button red small reject-campaign-button" data-campaign-id="${data}">Reject</button>
      `
    }
  }
]

const headers = [
  'Name',
  'Campaign type',
  'Created by',
  'Organisation',
  ''
]

const filters = []

const sorters = []

const order = []

export { columns, headers, filters, sorters, order }
