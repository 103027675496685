import useAct from 'CampaignStats/useAct'
import { RingroundSnackbar } from 'Shared/components'
import ExportConfirmationModal from 'Shared/components/ExportConfirmationModal'
import userCan from 'Shared/functions/userCan'
import useExtraActions from 'CampaignOverview/useAct'

export default function ExportStats({ phoneCampaign = false }) {
  const act = useAct()
  const [exportPassword, setExportPassword] = useState(null)
  const canExport = userCan('export_data')
  const extraActions = useExtraActions()

  const doExport = (mode) => {
    if (mode === 'messages') {
      extraActions.campaignStats.emailMessagesExport().then(setExportPassword)
    } else {
      act.campaignStats.emailExport(mode).then(setExportPassword)
    }
  }

  var i = 0

  return (
    <div className="root">
      <RingroundSnackbar />
      <div className="flex margin-bottom space-x-1">
        <div className="budget-stats">
          <div className="heading">
            <h1 className="margin-top double">Results</h1>
            {canExport && (
              <div className="actions">
                {phoneCampaign && (
                  <a
                    onClick={() => doExport('caller_stats')}
                    className="margin-right button secondary"
                  >
                    <SvgIconsDownload /> Export caller stats CSV
                  </a>
                )}
                {phoneCampaign && (
                  <a
                    onClick={() => doExport('all_calls')}
                    className="margin-right button secondary"
                  >
                    <SvgIconsDownload /> Export all calls as CSV
                  </a>
                )}
                <a onClick={() => doExport('default')} className="button primary">
                  <SvgIconsDownload /> Export results as CSV
                </a>
                {!phoneCampaign && (
                  <a onClick={() => doExport('messages')} className="button secondary margin-left">
                    <SvgIconsDownload /> Export all messages as CSV
                  </a>
                )}
              </div>
            )}
          </div>
          <p className="dark-grey">
            Only stats for multiple choice questions will be shown below. You can export
            other answers in a CSV.
          </p>
        </div>
      </div>

      <ExportConfirmationModal
        exportPassword={exportPassword}
        setExportPassword={setExportPassword}
        entityName="campaign" />
    </div>
  )
}

