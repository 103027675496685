import './Node.scoped.scss'
import ToggleNode from 'Shared/components/ToggleNode'
import Chip from 'Shared/components/Chip'

function formatParentNames(parentNames) {
  const delimiter = ' · '
  return <span className="parent-names">
    {parentNames.join(delimiter) + delimiter}
  </span>
}

// Presentation that's common to the different types of tree nodes in the org picker
// - Root and child org
// - External type
// - Text nodes
export default function Node({
  name,
  parentNames,
  depth = 0,
  children,
  onClick,
  expandable,
  expanded,
  toggle,
  label,
  textOnly = false,
}) {

  return (
    <div className={`org depth-${depth}`}>
      <div className="org-details" onClick={onClick}>
        {expandable && <ToggleNode expanded={expanded} onClick={toggle} />}
        {Array.isArray(parentNames) && formatParentNames(parentNames)}
        <span className="org-name">
          {name}
        </span>
        {label && <Chip>{label}</Chip>}
        {!textOnly && <div className="arrow"><SvgIconsArrowRight variant="light" /></div>}
      </div>
      {children}
    </div>
  )
}

