import FloatingMenu from 'Shared/components/FloatingMenu/FloatingMenu'
import Insights from 'Shared/components/Insights/Insights'
import './PageContainer.scoped.scss'
import useAct from '../useAct'
import Stat from 'Shared/components/Stat'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import SimplePaginator from 'Shared/components/SimplePaginator'
import { useWindowSize } from 'usehooks-ts'
import { MOBILE_SCREEN_WIDTH } from 'Shared/constants'
import { ConfirmationModal } from 'Shared/components/ConfirmationModal'
import QuestionStats from 'Shared/Surveys/components/QuestionStats'
import useScriptActions from 'Shared/ScriptEditor/hooks/useAct'
import restClient from 'Shared/hooks/restClient'

import ExportConfirmationModal from 'Shared/components/ExportConfirmationModal'

const items = [
  { name: 'Status', tag: 'status' },
  { name: 'Analytics', tag: 'analytics' },
  { name: 'Page', tag: 'page' },
]

const total = 100 // Total number of submissions - hardcoded for now

export default function PageContainer({ flowId, flowName, flowTemplateSlug, surveyId }) {
  const act = useAct()
  const scriptActions = useScriptActions()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { stats, status, slug } = useSel((s) => s.flow)
  const [overviewPage, setOverviewPage] = useState(1)
  const [loadingQuestions, setLoadingQuestions] = useState(true)
  const size = useWindowSize()
  const [exportPassword, setExportPassword] = useState(null)
  
  const doExport = () => {
    restClient.post(`/api/surveys/${surveyId}/export`).then((response) => {
      setExportPassword(response.data.password)
    })
  }

  useEffect(() => {
    if (flowId) {
      act.flow.load(flowId)
      act.flow.loadStats(flowId)
    }
  }, [flowId])

  useEffect(() => {
    if (surveyId) {
      setLoadingQuestions(true)
      scriptActions.scriptBuilder.load(surveyId).then(() => setLoadingQuestions(false))
      const questionInterval = setInterval(() => {
        scriptActions.scriptBuilder.load(surveyId)
      }, 5000)
      return () => {
        clearInterval(questionInterval)
      }
    }
  }, [surveyId])

  const stepsData = (stats?.steps || []).map((step) => {
    return {
      name: step.kind,
      amountLeft: step.total - step.completed,
      completed: step.completed,
      total: step.total
    }
  })

  const onClick = () => {
    act.flow.close(flowId)
    setShowConfirmationModal(false)
  }

  return (
  <>

    <div className="header flow">
      <a className='back' href="/admin/flows"></a>
      <div className="name-container">
        <h1>{flowName}</h1>
        <p style={{textTransform: 'uppercase'}}>{flowTemplateSlug}</p>
        <div className="actions">
          <a onClick={() => doExport('default')} className="button primary">
            <SvgIconsDownload /> Export results as CSV
          </a>
          <a href={`/admin/flows/${flowId}/build`} className="button primary"><SvgIconsEdit color="white" /> Edit</a>
         </div>
      </div>
    </div>


    
    <div className='flow-overview margin-top double'>
      <div className="left">
        {size.width >= MOBILE_SCREEN_WIDTH && <FloatingMenu items={items} />}
        {size.width < MOBILE_SCREEN_WIDTH && (
          <SimplePaginator
            totalPages={3}
            currentPage={overviewPage}
            onChange={setOverviewPage}
            labels={items.map((item) => item.name)}
            classes="margin-bottom"
          />
        )}
      </div>
      <div className='right'>
        {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 1) && (
          <div className='box gap' id='status'>
            <div className="poster-container">
              <div className="poster">
                { slug ? (
                  <iframe 
                    src={`/target/flows/${slug}`}
                    title="Embedded Page" 
                    className="iframe"
                    loading="lazy"
                  ></iframe>
                ) : (
                  <SvgCircLoading />
                )}
              </div>
            </div>
            <div className='main'>
              <div className='header row'>
                <h1 className='no-margin-top'>Page { status === 'closed' ? 'Closed' : status ? 'Active' : '' }</h1>
                <div className='actions'>
                  { status && status !== 'closed' && 
                    <button className="button red primary" onClick={() => setShowConfirmationModal(true)}>
                      Close
                    </button>
                  }
                  { showConfirmationModal &&
                    <ConfirmationModal
                      confirmValue='close'
                      description=''
                      hide={() => setShowConfirmationModal(false)}
                      onSubmit={onClick}
                      open={showConfirmationModal}
                    />
                  }
                </div>
              </div>
              <div className={`progress-bar margin-top double ${status === 'paused' ? 'paused' : ''}`}>
                {(stats?.flowsCompleted || 0) < total && <>
                  <div className="progress-bar-bar-container">
                    <div className="bar" style={{ width: `${percentage((stats?.flowsCompleted || 0), total)}%` }}></div>
                  </div>
                  <div className="progress-bar-info margin-top">
                    <div className="progress-bar-info-block --left">{(stats?.flowsCompleted || 0).toLocaleString()} out of {total.toLocaleString()} submissions received</div>
                    <div className="progress-bar-info-block --right">{percentage((stats?.flowsCompleted || 0), total)}%</div>
                  </div>
                </>}
              </div>
            </div>
          </div>
        )}
        {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 2) && (
          <div className='box grid margin-top double no-side-padding' id='analytics'>
            <div className="stat-container">
              <Stat
                borderBottom={false}
                size={'vertical'}
                label={`Started page`}
                primary={stats?.flowsStarted || 0}
              />
            </div>
            <div className="stat-container">
              <Stat
                borderBottom={false}
                size={'vertical'}
                label={`Completed page`}
                primary={stats?.flowsCompleted || 0}
              />
            </div>
            <div className="stat-container">
              <Stat
                borderBottom={false}
                size={'vertical'}
                label={`Completion rate`}
                primary={`${percentage(stats?.flowsCompleted || 0, stats?.flowsStarted || 0)}%`}
              />
            </div>
          </div>
        )}

        {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 2) && (
          <QuestionStats className="margin-top" />
        )}

        {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 3) && (
          <div className='box flex column margin-top double' id='page'>
            <h1 className='no-margin-top'>Page Steps</h1>
            <div className="step-container">
              <div className="info">
                <div className="example-box">
                  <span className="color-swatch" style={{ background: '#00F399' }}></span>
                  <p>Completed</p>
                </div>
                <div className="example-box">
                  <span className="color-swatch" style={{ background: 'rgba(24, 24, 91, 0.2)' }}></span>
                  <p>Started</p>
                </div>
              </div>
              <div className="data">
                <div className="chart-keys">
                  {stepsData.map((step, index) => (
                    <p key={index}>{step.name}</p>
                  ))}
                </div>
                <ResponsiveContainer width="100%" height={stepsData.length * 42}>
                  <BarChart
                    layout="vertical"
                    data={stepsData}
                    barSize={20}
                  >
                    <YAxis dataKey="name" type="category" width={100} hide={true} />
                    <XAxis type="number" hide={true} />
                    <Bar dataKey="completed" stackId="a" fill="#00F399" />
                    <Bar dataKey="amountLeft" stackId="a" fill="rgba(24, 24, 91, 0.1)" />
                  </BarChart>
                </ResponsiveContainer>
                <div className="text-data">
                  {stepsData.map((step, index) => (
                    <p key={index}>{step.completed}/{step.total}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>

    <ExportConfirmationModal
      exportPassword={exportPassword}
      setExportPassword={setExportPassword}
      entityName="flow" />
    </>
  )
}

function percentage(partialValue, totalValue) {
  if (partialValue === 0 || totalValue === 0) {
    return 0
  }
  return Math.round((100 * partialValue) / totalValue)
}
