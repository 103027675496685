const channelConfig = {
  whatsapp: {
    text: 'Share on WhatsApp',
    svg: 'CircleWhatsApp',
    getUrl: (publicUrl) => {
      return `https://wa.me/?text=${encodeURIComponent(publicUrl)}`
    }
  },
  twitter: {
    text: 'Share on Twitter',
    svg: 'CircleX',
    getUrl: (publicUrl) => {
      return `https://twitter.com/intent/tweet?url=${encodeURIComponent(publicUrl)}`
    }
  },
  facebook: {
    text: 'Share on Facebook',
    svg: 'CircleFacebook',
    getUrl: (publicUrl) => {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(publicUrl)}`
    }
  },
}

export default function ShareForm({ action, onSubmit, publicUrl }) {
  const { config } = action

  const handleCopy = async () => {
    await navigator.clipboard.writeText(publicUrl)
  }

  return <div className="share-form">
    { config.channels.map(channel => {
      const { text, svg, getUrl = () => {} } = channelConfig[channel] || {}

      let SvgIcon;
      if (svg === 'CircleWhatsApp') {
        SvgIcon = <SvgIconsCircleWhatsApp />;
      } else if (svg === 'CircleX') {
        SvgIcon = <SvgIconsCircleX />;
      } else if (svg === 'CircleFacebook') {
        SvgIcon = <SvgIconsCircleFacebook />;
      } else if (svg === 'CircleLinkedIn') {
        SvgIcon = <SvgIconsCircleLinkedIn />;
      }

      return <div className='margin-bottom'>
        <a key={channel} className={`share-button flex align-center gap-12`} target="_blank" href={getUrl(publicUrl)}>{SvgIcon}{text}</a>
      </div>
    }) }

    <div className="margin-top share-link-container">
      <h4 className="larger">Share Link</h4>
      <div className="share-box flex align-center gap-8">
        <input value={publicUrl} readOnly />
        <SvgIconsCopy onClick={handleCopy} className="copy-icon" />
      </div>
      <p className="smaller lighter">Copy this link to share</p>
    </div>
  </div>
}
