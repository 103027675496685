export default function SvgNote(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7929 9.12589C16.1834 9.51641 16.1834 10.1496 15.7929 10.5401L11.8117 14.5214C11.6217 14.7113 11.3633 14.8168 11.0947 14.8142L9.67492 14.8002C9.13625 14.7949 8.69858 14.3639 8.68508 13.8253L8.65073 12.4559C8.64387 12.1821 8.74963 11.9174 8.94331 11.7237L12.9599 7.70711C13.3504 7.31658 13.9836 7.31658 14.3741 7.70711L15.7929 9.12589Z" fill="#00AE6E"/>
      <rect x="8.49414" y="16.1538" width="7.51211" height="1.00161" rx="0.500807" fill="#00AE6E"/>
      <path opacity="0.3" d="M5.85714 2H13.7364C14.0911 2 14.4343 2.12568 14.7051 2.35474L19.4687 6.38394C19.8057 6.66895 20 7.08788 20 7.5292V20.0833C20 21.8739 19.9796 22 18.1429 22H5.85714C4.02045 22 4 21.8739 4 20.0833V3.91667C4 2.12612 4.02045 2 5.85714 2Z" fill="#00AE6E"/>
    </svg>
  )
}

