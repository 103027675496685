import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import actionFactory from 'Shared/hooks/actionFactory'
import { actions as localActionsF } from './local'

const adapter = createEntityAdapter()

const reducer = createSlice({
  name: 'answers',
  initialState: adapter.getInitialState(),
  reducers: {
    upsert: adapter.upsertOne,
    add: adapter.addOne,
    set: adapter.setOne,
    setAll: adapter.setAll,
    remove: adapter.removeOne,
  },
}).reducer

const actions = (dis, store, restClient) => {
  const localActions = localActionsF(dis, store, restClient)
  const factoryActions = actionFactory(dis, store, 'answer', {}, {
    add: (dis, store, entity) => (data = {}) => {
      const id = uuidv4()
      const state = store.getState()
      const digitOrderMax = _.max(_.values(state.answers.entities).filter(a => a.active).map(q => q.digitOrder))
      const digitOrder = isNaN(digitOrderMax) ? 1 : digitOrderMax + 1
      dis({ type: `answers/upsert`, payload: { id, digitOrder, active: true, nextQuestionId: null, setsGoal: false, action: 'next_question', ...data } })
      localActions.setDirty(true)
    },
    markInactive: (dis, store, entity) => (id) => {
      dis({ type: `answers/upsert`, payload: { id, active: false } })
      localActions.setDirty(true)
    }
  }, {
    afterChange: () => localActions.setDirty(true)
  })

  return {
    ...factoryActions,
  }
}

export { reducer, actions }
export default { reducer, actions }
