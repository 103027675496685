import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import actionFactory from 'Shared/hooks/actionFactory'
import { actions as localActionsF } from './local'

const adapter = createEntityAdapter()

const reducer = createSlice({
  name: 'questions',
  initialState: adapter.getInitialState(),
  reducers: {
    upsert: adapter.upsertOne,
    add: adapter.addOne,
    set: adapter.setOne,
    setAll: adapter.setAll,
    remove: adapter.removeOne,
    upsertSettings: (state, { payload }) => {
      const { id, settings } = payload
      const currentSettings = state.entities[id].settings
      state.entities[id].settings = { ...currentSettings, ...settings }
    },
    moveQuestion: (state, { payload }) => {
      // digit order is 1-based
      const { id, newPageNumber, newDigitOrder } = payload
      const question = state.entities[id]
      const oldPageNumber = question.pageNumber
      const oldDigitOrder = question.digitOrder
      const questions = _.values(state.entities).filter(q => q.active) // only deal with active questions

      // for all questions on the old page, move them up
      questions.filter(q => q.pageNumber === oldPageNumber && q.digitOrder > oldDigitOrder).forEach(q => {
        state.entities[q.id].digitOrder--
      })

      // for all questions on the new page, move them down
      questions.filter(q => q.pageNumber === newPageNumber && q.digitOrder >= newDigitOrder).forEach(q => {
        state.entities[q.id].digitOrder++
      })

      // update the question
      question.pageNumber = newPageNumber
      question.digitOrder = newDigitOrder
    },
    markInactive: (state, { payload }) => {
      const { id } = payload
      const question = state.entities[id]
      const { pageNumber, digitOrder } = question
      const questions = _.values(state.entities).filter(q => q.active && q.pageNumber === pageNumber) // only deal with active questions on same page

      questions.filter(q => q.digitOrder > digitOrder).forEach(q => {
        state.entities[q.id].digitOrder--
      })

      question.active = false
    }
  },
}).reducer

const actions = (dis, store, restClient) => {
  const localActions = localActionsF(dis, store, restClient)
  const factoryActions = actionFactory(dis, store, 'question', {}, {
    add: (dis, store, entity) => (data = {}) => {
      const id = uuidv4()
      const state = store.getState()
      const { pageNumber = 1 } = data

      const digitOrderMax = _.max(_.values(state.questions.entities).filter(q => q.active && q.pageNumber === pageNumber).map(q => q.digitOrder))
      const digitOrder = isNaN(digitOrderMax) ? 1 : digitOrderMax + 1
      dis({ type: `questions/upsert`, payload: { id, digitOrder, active: true, ...data } })
      localActions.setDirty(true)
    },
    moveQuestion: (dis, store, entity) => (questionId, newPageNumber, newDigitOrder) => {
      dis({ type: `questions/moveQuestion`, payload: { id: questionId, newPageNumber, newDigitOrder } })
      localActions.setDirty(true)
    },
    markInactive: (dis, store, entity) => (id) => {
      dis({ type: `questions/markInactive`, payload: { id, active: false } })

      // Mark the answers inactive too
      const answers = _.values(store.getState().answers.entities)
      answers.filter(a => a.questionId === id).forEach(({ id }) => {
        dis({ type: `answers/upsert`, payload: { id, active: false } })
      })

      // Mark any answers that are pointing at this as 'next_question'
      answers.filter(a => a.nextQuestionId === id && a.action === 'go_to_question').forEach(({ id }) => {
        dis({ type: `answers/upsert`, payload: { id, action: 'next_question', nextQuestionId: null } })
      })
      localActions.setDirty(true)
    },
    upsertSettings: (dis, store, entity) => (id, settings) => {
      dis({ type: `questions/upsertSettings`, payload: { id, settings } })
      localActions.setDirty(true)
    }
  },{
    afterChange: () => localActions.setDirty(true)
  })

  return {
    ...factoryActions,
  }
}

export { reducer, actions }
export default { reducer, actions }
