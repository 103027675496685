import './MultipleChoice.scoped.scss'
import Radio from 'Shared/components/Radio'

export default function OptInRadio({ response, setResponse, name, disabled = false, settings }) {
  const handleChange = (event) => {
    if(event.target.checked){
      setResponse(event.target.value);
    }
  }

  const yesLabel = settings.yesLabel || 'Yes'
  const noLabel = settings.noLabel || 'No'

  return (
    <div className="answers">
      <Radio
        value='y'
        onChange={handleChange}
        disabled={disabled}
        checked={response === 'y'}
        label={yesLabel}
        key='y'
      />
      <Radio
        value='n'
        onChange={handleChange}
        disabled={disabled}
        checked={response === 'n'}
        label={noLabel}
        key='n'
      />
    </div>
  )
}
