import { useAct } from 'FlowBuilder/store'
import Select from 'react-select';
import Button from 'Shared/components/Button'
import './SurveyForm.scoped.scss'
import questionTypes from './question-types'
import PillEditor from 'Shared/components/PillEditor/PillEditor'

export default function SurveyForm({ surveyId, pageNumber }) {
  const questions = useSel(s => _.values(s.questions.entities).filter(q => q.active && q.surveyId === surveyId && q.pageNumber === pageNumber))
  const orderedQuestions = _.orderBy(questions, 'digitOrder')

  return <div>
    <h2 className='no-margin-top'>Page {pageNumber}</h2>
    {orderedQuestions.map(q => <QuestionForm key={q.id} questionId={q.id} />)}
  </div>
}

function QuestionForm({ questionId }) {
  const question = useSel(s => s.questions.entities[questionId])
  const mergeTags = useSel(s => s.builder.mergeTags)
  const [active, setActive] = useState(true)
  const questionConfig = questionTypes[question.questionType]

  if (!questionTypes[question.questionType]) {
    throw new Error(`Unsupported question type: ${question.questionType}`)
  }

  const act = useAct()
  const answers = useSel(s => _.values(s.answers.entities).filter(a => a.active && a.questionId === questionId))
  const { question: questionText, settings: { required } = { required: false } } = question

  function updateQuestion(data) {
    act.questions.upsert(questionId, data)
  }

  function setSetting(key, value) {
    act.questions.upsertSettings(questionId, { [key]: value })
  }

  const classNames = ['box question-form margin-bottom']
  if (active) {
    classNames.push('active')
  }

  return <div className={classNames.join(' ')}>
    <div className="flex-center gap-10">
      <div className="fake-input flex-center">
        <span>{questionConfig.icon()}</span>
        {questionConfig.name}
      </div>
      {questionConfig.multiline ?
        <span className="grow"></span> :
        (
          active ?
            <PillEditor className="grow" initialValue={questionText} onChange={(text) => updateQuestion({ question: text })} availableTags={mergeTags} condensed singleLine /> :
            <div className="fake-input grow">{questionText}</div>
        )
      }

      {active && <Button naked square-button onClick={() => setActive(false)}><SvgIconsUpCaret /></Button>}
      {!active && <Button naked square-button onClick={() => setActive(true)}><SvgIconsDownCaret /></Button>}
    </div>

    {active &&
      <>
        {questionConfig.showRequired &&
          <div className='field'>
            <label className="flex-center gap-10">
              <input type='checkbox' checked={!!required} onChange={e => setSetting('required', e.target.checked)} />
              Required
            </label>
          </div>
        }

        <div className="field">
          {questionConfig.multiline &&
            <PillEditor
              className="grow multiline"
              initialValue={questionText}
              onChange={(text) => updateQuestion({ question: text })}
              availableTags={mergeTags} />
          }
        </div>

        {questionConfig.hasAnswers &&
          <>
            {answers.map(a => <AnswerForm key={a.id} answerId={a.id} />)}
            <Button naked onClick={() => act.answers.add({ questionId })}>Add option</Button>
          </>
        }

        { (questionConfig.settings || []).map(setting => <QuestionSetting key={setting.key} setting={setting} settings={question.settings} setSetting={setSetting} />)}
      </>
    }
  </div>
}

function QuestionSetting({ setting, settings, setSetting }) {
  const { key, label, type, options } = setting
  const value = settings[key]

  console.log('setting', setting, settings)

  function handleChange(e) {
    setSetting(key, e.target.value)
  }

  const selectOptions = options ? options.map(o => ({ value: o.value, label: o.label })) : []

  return <div className='field'>
    <label>{label}</label>
    { type === 'text' && <input type='text' value={value} onChange={handleChange} /> }

    { type === 'multi_select' && <Select isMulti options={selectOptions} value={selectOptions.filter(o => _.includes(value, o.value))} onChange={o => setSetting(key, o.map(x => x.value))} /> }

  </div>
}

function AnswerForm({ answerId }) {
  const act = useAct()
  const answer = useSel(s => s.answers.entities[answerId])
  const { answer: answerText } = answer

  function updateAnswer(data) {
    act.answers.upsert(answerId, data)
  }

  return <div className="answer-form">
    <div className='flex-center gap-10'>
      <input className="grow" type='text' value={answerText} onChange={e => updateAnswer({ answer: e.target.value })} />
      <button className="button naked" onClick={() => act.answers.markInactive(answerId)}>
        <SvgIconsCross />
      </button>
    </div>
  </div>
}
