import SendProgress from 'SendBlast/components/SendProgress'
import ExportConfirmationModal from 'Shared/components/ExportConfirmationModal'
import userCan from 'Shared/functions/userCan'
import useExtraActions from 'CampaignOverview/useAct'

function SendBar({entityBlast, sendingInProgress, allFailed}) {
  const { campaignType } = useSel((s) => s.campaign || {})
  const campaignId = useSel((s) => s.local.campaignId)
  const canExport =
    _.includes(['sms_conversations', 'sms_blast'], campaignType) && userCan('export_data')
  const canExportMessages = campaignType === 'sms_conversations' || campaignType === 'sms_blast'
  const [exportPassword, setExportPassword] = useState(null)
  const extraActions = useExtraActions()

  const doExport = (messages) => {
    if (messages) {
      extraActions.campaignStats.emailMessagesExport().then(setExportPassword)
    } else {
      extraActions.campaignStats.emailExport().then(setExportPassword)
    }
  }

  return (
    <div id="progress" className="box margin-bottom">
      {sendingInProgress && (
        <SendProgress
          campaignId={campaignId}
          leftAligned={false}
          selfContained={true}
          entityType={campaignType === 'email' ? "emailBlast" : "textMessageBlast"}
          entityId={entityBlast.id}
        />
      )}
      {!sendingInProgress && (
        <h1 className={`${canExport ? '' : 'no-margin-bottom'}`}>
          Sending complete.
        </h1>
      )}

      {allFailed && (
        <p className="error">
          All of your {campaignType === 'email' ? 'emails' : 'texts'} failed to send. Please get in touch with support.
        </p>
      )}

      {canExport && (
        <div className="margin-top">
          <a
            onClick={() => doExport(false)}
            className="button small primary margin-right"
          >
            <SvgIconsDownload /> Export results
          </a>
          {canExportMessages && (
            <a
              onClick={() => doExport(true)}
              className="button small secondary"
            >
              <SvgIconsDownload /> Export all messages
            </a>
          )}

          <ExportConfirmationModal
            exportPassword={exportPassword}
            setExportPassword={setExportPassword}
            entityName="campaign"
          />
        </div>
      )}
    </div>
  )
}

export default SendBar
