import * as React from 'react'

export default function SvgUpCaret(props) {
  return (
    <svg width={18} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_5_3)">
        <path d="M16.5 9L9 1.5L1.5 9" stroke="#191936" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_5_3">
          <rect width="18" height="10" fill="white" transform="matrix(1 0 0 -1 0 10)"/>
        </clipPath>
      </defs>
    </svg>

  )
}

