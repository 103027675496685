import ServerWaitButton from 'Shared/components/ServerWaitButton'

const STATUSES = {
  'sent': 'Your sample has been sent.',
  'error': "Sorry, we couldn't send a sample to that phone number.",
  'invalid_number': "Please check the phone number you've entered. It doesn't look quite right."
}

export default function SendSampleTextMessage ({ renderedBody, open, close }) {
  const act = useAct()
  const sampleStatus = useSel(s => s.campaignBuilder.sampleStatus)
  const userPhoneNumber = useSel(s => s.local.userPhoneNumber)
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber || '')

  function sendSample() {
    return act.campaignBuilder.sendSampleTextMessage(phoneNumber, renderedBody)
  }

  return <div className={classes("modal-container", {visible: open})}>
    <div className="modal">
      <div className="header">
        <h2>Send a sample message</h2>
        <a className="close js-close-modal" onClick={close}>X</a>
      </div>

      <div className='content'>
        <div className='padded'>
          <p className='no-margin-top bold'>Send a sample to</p>
          <div className='input-with-button'>
            <input type='text' value={phoneNumber} placeholder={'Phone number'} onChange={(e) => setPhoneNumber(e.target.value)} />
            <ServerWaitButton className="button primary" disabled={phoneNumber.length === 0} onClick={() => sendSample()}>Send sample</ServerWaitButton>
          </div>
          { sampleStatus && <p className={`${sampleStatus === 'sent' ? 'dark-grey' : 'error'}`}>{ STATUSES[sampleStatus] }</p> }
        </div>
      </div>
    </div>
  </div>
}

