import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { useStore } from 'react-redux'
import restClient from 'Shared/hooks/restClient'

import answers from 'FlowTaker/store/answers'
import local from 'FlowTaker/store/local'
import questions from 'FlowTaker/store/questions'
import taker from 'FlowTaker/store/taker'

// the reducers and actions for each slice are stored together in slices/sliceName.js
const slices = {
  answers,
  local,
  questions,
  taker
}

const store = configureStore({
  middleware: [...getDefaultMiddleware()],
  reducer: _.mapValues(slices, (slice) => slice.reducer),
  devTools: {
    name: 'Flow taker',
  },
})

const useAct = () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () => _.mapValues(slices, (fn) => {
      return fn.actions(dis, store, restClient)
    }),
    []
  )
}

export { store, useAct }
