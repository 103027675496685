import TakeSurveyForm from './TakeSurveyForm'
import TemplateRender from '../TemplateRender'
import Preview from '../Preview'
import FieldEdit from '../FieldEdit'

import { useAct } from 'FlowBuilder/store'

export default function SurveyActionView() {
  const act = useAct()
  const templateFields = useSel(s => s.builder.flow.templateFields)
  const flowFields = useSel(s => s.builder.flow.flowFields)
  const context = { template: templateFields, flow: { ...flowFields, currentActionKind: 'survey' } }

  const selectedActionId = useSel(s => s.local.selectedActionId)
  const selectedPageNumber = useSel(s => s.local.selectedPageNumber)
  const surveyId = useSel(s => s.builder.flow.actions.find(a => a.id === selectedActionId).surveyId)
  const questions = useSel(s => _.values(s.questions.entities).filter(q => q.surveyId === surveyId))
  const { body, head, styles } = useSel(s => s.builder.flow.template)

  const componentMap = useMemo(() => ({
    TakeSurveyForm: <>
      <TakeSurveyForm
        surveyId={surveyId}
        questions={questions}
        pageNumber={selectedPageNumber}
        onSubmit={() => Promise.resolve()}
      />
    </>,
    FieldEdit,
  }), [surveyId, selectedPageNumber])

  return <>
    <Preview styles={styles} head={head}>
      <TemplateRender htmlContent={body} componentMap={componentMap} context={context} edit />
    </Preview>
  </>
}
