import { createSlice } from '@reduxjs/toolkit'

const reducer = createSlice({
  name: 'local',
  initialState: {
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload })
  },
}).reducer

const actions = (dis, store, restClient) => {
  return {
    set: (payload) => {
      dis({ type: 'local/set', payload })
    },
    upsert: (payload) => {
      dis({ type: 'local/upsert', payload })
    },
  }
}

export { reducer, actions }
export default { reducer, actions }
