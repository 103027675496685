import { createSlice } from '@reduxjs/toolkit'
import { actions as localActionsF } from './local'
import { actions as questionActionsF } from './questions'
import { actions as answerActionsF } from './answers'

import renderContext from './lib/renderContext'

const reducer = createSlice({
  name: 'taker',
  initialState: {
    flow: {
      actions: []
    },
    flowTaker: {},
    context: {
      template: {},
      flow: {}
    }
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload })
  },
}).reducer

const actions = (dis, store, restClient) => {
  const localActions = localActionsF(dis, store, restClient)
  const questionsActions = questionActionsF(dis, store, restClient)
  const answersActions = answerActionsF(dis, store, restClient)

  function load(flowId, { forget }) {
    const params = _.pickBy({ forget }, value => !!value)
    return restClient.get(`/api/target/flows/${flowId}`, { params })
      .then(({ data }) => {
        const { flow, flowTaker } = data
        const context = renderContext({ flow, flowTaker })
        dis({ type: 'taker/set', payload: { flow, flowTaker, context } })

        const surveys = _.compact(flow.actions.map(a => a.survey))

        const questions = _.flatMap(surveys, s => s.questions)
        const answers = _.flatMap(questions, q => q.answers)

        questionsActions.setAll(questions)
        answersActions.setAll(answers)
      })
  }

  function submitPage({ pageNumber, actionId, responses }) {
    const flowTakerId = store.getState().taker.flowTaker.id
    const flow = store.getState().taker.flow
    const flowId = flow.id

    return restClient.post(`/api/target/flows/${flowId}/answer_survey`, { pageNumber, actionId, responses, flowTakerId })
      .then(({ data }) => {
        const { flowTaker } = data
        dis({ type: 'taker/upsert', payload: { flowTaker, context: renderContext({ flow, flowTaker }) } })
      })
  }

  function completeAction({ actionId }) {
    const flowTakerId = store.getState().taker.flowTaker.id
    const flow = store.getState().taker.flow
    const flowId = flow.id

    return restClient.post(`/api/target/flows/${flowId}/complete_action`, { actionId, flowTakerId })
    .then(({ data }) => {
      const { flowTaker } = data
      dis({ type: 'taker/upsert', payload: { flowTaker, context: renderContext({ flow, flowTaker }) } })
    })
  }

  return {
    load,
    submitPage,
    completeAction,
    set: (payload) => {
      dis({ type: 'taker/set', payload })
    },
  }
}

export default { reducer, actions }
