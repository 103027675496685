import './Wizard.scoped.scss'

const getIcon = (iconType) => {
  switch (iconType) {
    case "petition":
      return <SvgIconsSignature />
    case "survey":
      return <SvgIconsCircleQuestion />
    default:
      return null
  }
}

const Wizard = ({ wizards, setWizard }) => {
  return (
    <>
      <div className="header margin-left">
        <button className="button naked margin-bottom" onClick={() => window.location.href = '/admin/flows'}><SvgIconsBack /></button>
        <h1 className="margin-bottom">Choose Wizard</h1>
      </div>
      <div className='wizard'>
        {wizards.map(wizard => (
          <a className="card large selectable" onClick={() => setWizard(wizard)} key={wizard.id}>
            {getIcon(wizard.icon)}
            <h2>{wizard.name}</h2>
            <p>{wizard.description}</p>
          </a>
        ))}
      </div>
    </>
  )
}

export default Wizard
