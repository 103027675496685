import RedirectActionForm from 'FlowBuilder/components/ActionForms/RedirectActionForm'
import ShareActionForm from 'FlowBuilder/components/ActionForms/ShareActionForm'
import SurveyActionForm from 'FlowBuilder/components/ActionForms/SurveyActionForm'

import SurveyActionView from 'FlowBuilder/components/ActionViews/Survey/SurveyActionView'
import ShareActionView from 'FlowBuilder/components/ActionViews/Share/ShareActionView'
import DonateActionView from './ActionViews/Donate/DonateActionView'
import Setup from './Setup'

export default function Main() {
  const navItem = useSel(s => s.local.navItem)
  const selectedActionId = useSel(s => s.local.selectedActionId)
  const selectedPageNumber = useSel(s => s.local.selectedPageNumber)
  const selectedAction = useSel(s => s.builder.flow?.actions?.find(a => a.id === selectedActionId))

  if (navItem === 'build') {
    if (selectedAction) {
      switch (selectedAction.kind) {
        case 'redirect':
          return <RedirectActionForm actionId={selectedActionId} />
        case 'share':
          return <ShareActionForm actionId={selectedActionId} />
        case 'survey':
          return <SurveyActionForm actionId={selectedActionId} pageNumber={selectedPageNumber} />
        default:
          return <div>Unknown action kind: {selectedAction.kind}</div>
      }
    }
  } else if (navItem === 'preview') {
    if (selectedAction) {
      switch (selectedAction.kind) {
        case 'survey':
          return <SurveyActionView actionId={selectedActionId} pageNumber={selectedPageNumber} />
        case 'share':
          return <ShareActionView actionId={selectedActionId} />
        case 'donate':
          return <DonateActionView actionId={selectedActionId} />
        default:
          return <div>Unknown action kind: {selectedAction.kind}</div>
      }
    }
  } else if(navItem === 'setup') {
    return <Setup />
  }

  return null
}
