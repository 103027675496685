import SurveyForm from './Survey/SurveyForm'

export default function SurveyActionForm({ actionId, pageNumber }) {
  const surveyId = useSel(s => s.builder.flow?.actions?.find(a => a.id === actionId)?.surveyId)
  const refreshKey = useSel(s => s.local.refreshKey)

  return <div className="box grow scroll-y margin-bottom flex-scroll-fix">
    <SurveyForm surveyId={surveyId} pageNumber={pageNumber} key={refreshKey} />
  </div>
}
