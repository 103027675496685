import * as React from 'react'

function CircleLinkedIn(props) {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="24" cy="24" r="24" fill="#0072B1"/>
      <path d="M17.98 14.5C17.98 15.881 16.87 17 15.5 17C14.13 17 13.02 15.881 13.02 14.5C13.02 13.12 14.13 12 15.5 12C16.87 12 17.98 13.12 17.98 14.5ZM18 19H13V35H18V19ZM25.982 19H21.014V35H25.983V26.601C25.983 21.931 32.012 21.549 32.012 26.601V35H37V24.869C37 16.989 28.078 17.276 25.982 21.155V19Z" fill="white"/>
    </svg>
  )
}

export default CircleLinkedIn
