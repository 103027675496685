import { useAct } from 'FlowTaker/store'
import TakeSurveyForm from 'FlowBuilder/components/ActionViews/Survey/TakeSurveyForm'
import TemplateRender from 'FlowBuilder/components/ActionViews/TemplateRender'

export default function SurveyAction({ actionId, surveyId }) {
  const act = useAct()
  const bodyHtml = useSel(s => s.taker.flow.template.body)
  const templateFields = useSel(s => s.taker.flow.template.fields || [])
  const templateFieldsValues = useSel(s => s.taker.flow.templateFields)
  const transformedTemplateFields = _.mapValues(templateFieldsValues, (value, key) => {
    const field = templateFields.find(f => _.camelCase(f.slug) === key)
    if (!field) return value

    if (field.image) {
      return `<img src="${value}" alt="${field.label}" />`
    }

    return value
  })
  const flowFields = useSel(s => s.taker.flow.flowFields)
  const context = useSel(s => s.taker.context)
  const state = useSel(s => s)
  const currentPageNumber = useSel(s => s.taker.flowTaker.currentPageNumber)

  const handleSubmit = (responses) => {
    return act.taker.submitPage({ pageNumber: currentPageNumber, actionId, responses })
  }

  const componentMap = useMemo(() => ({
    TakeSurveyForm: <>
      <TakeSurveyForm surveyId={surveyId} onSubmit={handleSubmit} pageNumber={currentPageNumber} />
    </>,
  }), [surveyId, currentPageNumber])

  return <div className='template-body'>
    <TemplateRender htmlContent={bodyHtml} componentMap={componentMap} context={context} />
  </div>
}
