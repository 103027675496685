import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import actionFactory from 'Shared/hooks/actionFactory'
import { actions as localActionsF } from './local'

const adapter = createEntityAdapter()

const reducer = createSlice({
  name: 'actions',
  initialState: adapter.getInitialState(),
  reducers: {
    upsert: adapter.upsertOne,
    add: adapter.addOne,
    set: adapter.setOne,
    setAll: adapter.setAll,
    remove: adapter.removeOne,
  },
}).reducer

const actions = (dis, store, restClient) => {
  const localActions = localActionsF(dis, store, restClient)
  const factoryActions = actionFactory(dis, store, 'action', {}, {}, {
    afterChange: () => localActions.setDirty(true)
  })

  function persist(actionId) {
    const flowId = store.getState().builder.flow.id
    const action = store.getState().actions.entities[actionId]

    return restClient.put(`/api/flows/${flowId}/actions/${actionId}`, { flowAction: action })
      .then(({ data: action }) => {
        dis({ type: 'actions/set', payload: action })
      })
  }

  return {
    persist,
    ...factoryActions
  }
}

export { reducer, actions }
export default { reducer, actions }
