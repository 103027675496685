import Fade from '@material-ui/core/Fade'
import InfoSection from './InfoSection/InfoSection'
import Grid from '@material-ui/core/Grid'
import SubscriptionList from 'Shared/components/SubscriptionList'


const ExtendedInfo = () => {
  const target = useSel((s) => s.target)
  const subscriptionStatuses = useSel((s) => s.target.subscriptionStatuses)

  return (
    <Fade in={!_.isEmpty(target)}>
      {_.isEmpty(target) ? (
        <div />
      ) : (
        <div className="box extended-info">
          {target.sections.map((section, idx) => (
            <Grid item container key={section.title}>
              <InfoSection section={section} />
              {idx < target.sections.length - 1 && (
                <div className="divider" style={{ margin: '40px 0 30px 0' }} />
              )}
            </Grid>
          ))}

          <div className="divider"></div>

          <p className="larger bold capitalise no-margin-top">Subscriptions</p>
          <SubscriptionList endpoint="/api/subscriptions" subscriptions={subscriptionStatuses} />
        </div>
      )}
    </Fade>
  )
}

export default ExtendedInfo
