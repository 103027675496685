import './Stat.scoped.scss'

export default function Stat({ size = 'normal', searchId = '', borderBottom=true, number=null, icon, primary, secondary, label, showPulse = false }) {
  function statContent() {
    return <>
      <p className='primary break-word'>
        { showPulse && <div className="pulser margin-right"></div> }
        { Number.isInteger(primary) ? primary.toLocaleString() : primary }
      </p>
      { number !== null && <p className='number'>{ number.toLocaleString() }</p> }
      { label && <div className="label">
          <p>{ label }</p>
      </div> }
    </>
  }

  const path = `/admin/searches/${searchId}`;
  return <div className={`text-stat ${size} ${borderBottom ? 'border-bottom' : ''}`}>
      { icon && <div className={`icon ${size}`}>{ icon }</div> }
      { !searchId && <div className='content'>
            { statContent() }
      </div> }
      { searchId && <a href={path} className='content'>
            { statContent() }
      </a> }
  </div>  
}
