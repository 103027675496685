import * as React from 'react'

function Text(props) {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.6314 8.21714C13.5304 8.00239 13.3704 7.82081 13.1701 7.69363C12.9698 7.56644 12.7374 7.4989 12.5001 7.4989C12.2628 7.4989 12.0304 7.56644 11.8301 7.69363C11.6298 7.82081 11.4698 8.00239 11.3689 8.21714L1.36886 29.4671C1.299 29.6157 1.25907 29.7766 1.25138 29.9405C1.24368 30.1045 1.26835 30.2684 1.324 30.4229C1.43637 30.7348 1.66806 30.9893 1.96808 31.1304C2.26811 31.2715 2.6119 31.2877 2.92383 31.1753C3.23575 31.0629 3.49026 30.8312 3.63136 30.5312L6.23449 24.9999H18.7657L21.3689 30.5312C21.4387 30.6798 21.5372 30.8131 21.6586 30.9236C21.78 31.0341 21.922 31.1196 22.0764 31.1753C22.2309 31.2309 22.3948 31.2556 22.5587 31.2479C22.7227 31.2402 22.8836 31.2003 23.0321 31.1304C23.1807 31.0605 23.314 30.9621 23.4246 30.8407C23.5351 30.7193 23.6206 30.5773 23.6762 30.4229C23.7319 30.2684 23.7566 30.1045 23.7489 29.9405C23.7412 29.7766 23.7012 29.6157 23.6314 29.4671L13.6314 8.21714ZM7.41105 22.4999L12.5001 11.6859L17.5892 22.4999H7.41105ZM31.2501 14.9999C29.2564 14.9999 27.6986 15.5421 26.6204 16.6124C26.3947 16.8478 26.2699 17.1619 26.2725 17.488C26.2752 17.814 26.4052 18.1261 26.6347 18.3577C26.8643 18.5893 27.1752 18.722 27.5012 18.7276C27.8272 18.7331 28.1425 18.6111 28.3798 18.3874C28.9736 17.7984 29.9423 17.4999 31.2501 17.4999C33.3173 17.4999 35.0001 18.9062 35.0001 20.6249V21.1281C33.8908 20.3859 32.5847 19.993 31.2501 19.9999C27.8032 19.9999 25.0001 22.5234 25.0001 25.6249C25.0001 28.7265 27.8032 31.25 31.2501 31.25C32.5853 31.2558 33.8915 30.8612 35.0001 30.1171C35.0157 30.4487 35.1623 30.7604 35.4077 30.9839C35.6531 31.2073 35.9772 31.3241 36.3087 31.3085C36.6402 31.293 36.952 31.1464 37.1754 30.901C37.3989 30.6556 37.5157 30.3315 37.5001 30V20.6249C37.5001 17.5234 34.697 14.9999 31.2501 14.9999ZM31.2501 28.75C29.1829 28.75 27.5001 27.3437 27.5001 25.6249C27.5001 23.9062 29.1829 22.4999 31.2501 22.4999C33.3173 22.4999 35.0001 23.9062 35.0001 25.6249C35.0001 27.3437 33.3173 28.75 31.2501 28.75Z" fill="#F0C266"/>
    </svg>
  )
}

export default Text
