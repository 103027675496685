import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import actionFactory from 'Shared/hooks/actionFactory'
import { actions as questionActionsF } from './questions'
import { actions as answerActionsF } from './answers'
import { actions as localActionsF } from './local'

const adapter = createEntityAdapter()

const reducer = createSlice({
  name: 'surveys',
  initialState: adapter.getInitialState(),
  reducers: {
    upsert: adapter.upsertOne,
    add: adapter.addOne,
    set: adapter.setOne,
    setAll: adapter.setAll,
    remove: adapter.removeOne,
  },
}).reducer

const actions = (dis, store, restClient) => {
  const questionsActions = questionActionsF(dis, store, restClient)
  const answersActions = answerActionsF(dis, store, restClient)
  const localActions = localActionsF(dis, store, restClient)

  const factoryActions = actionFactory(dis, store, 'survey', {}, {
    addPage: (dis, store, entity) => (data = {}) => {
      const { insertIndex, surveyId } = data
      const pageCount = store.getState().builder.pageCount
      const newPageCount = pageCount + 1

      // move all questions with pageNumber > insertIndex up by 1 (indexes are 1-based)
      const questions = store.getState().questions.entities
      const questionsToMove = _.values(questions).filter(q => q.pageNumber > insertIndex && q.active && q.surveyId === surveyId)
      questionsToMove.forEach(q => {
        dis({ type: 'questions/upsert', payload: { ...q, pageNumber: q.pageNumber + 1 } })
      })

      dis({ type: 'builder/upsert', payload: { pageCount: newPageCount } })
      localActions.setDirty(true)
    },
    removePage: (dis, store, entity) => (data = {}) => {
      const { pageNumber, surveyId } = data
      const pageCount = store.getState().builder.pageCount
      const newPageCount = pageCount - 1

      // remove all questions on the page
      const questions = store.getState().questions.entities
      const questionsToRemove = _.values(questions).filter(q => q.pageNumber === pageNumber && q.active && q.surveyId === surveyId)
      questionsToRemove.forEach(q => {
        questionsActions.markInactive(q.id)
      })

      // move all questions with pageNumber > pageNumber down by 1
      const questionsToMove = _.values(questions).filter(q => q.pageNumber > pageNumber && q.active && q.surveyId === surveyId)
      questionsToMove.forEach(q => {
        dis({ type: 'questions/upsert', payload: { ...q, pageNumber: q.pageNumber - 1 } })
      })

      dis({ type: 'builder/upsert', payload: { pageCount: newPageCount } })
      localActions.setDirty(true)
    }
  }, {
    afterChange: () => localActions.setDirty(true)
  })

  function load(surveyId) {
    const refreshKey = store.getState().local.refreshKey

    return restClient.get(`/api/surveys/${surveyId}`)
      .then(({ data: survey }) => {
        dis({ type: 'surveys/set', payload: _.omit(survey, ['questions', 'answers']) })
        const questions = survey.questions.filter(q => q.active)
        const answers = _.flatMap(questions, q => q.answers)

        questionsActions.setAll(questions)
        answersActions.setAll(answers)

        const pageCount = _.max(questions.map(q => q.pageNumber))
        dis({ type: 'builder/upsert', payload: { pageCount: pageCount || 1 } })
        dis({ type: 'local/upsert', payload: { refreshKey: refreshKey + 1 } })

        localActions.setDirty(false)
      })
  }

  function persistQuestions(surveyId) {
    const { questions, answers, surveys } = store.getState()
    const survey = surveys.entities[surveyId]
    const campaignId = survey.campaignId

    const identifier = window.btoa(campaignId)
    const params = {
      questions: _.values(questions.entities),
      answers: _.values(answers.entities),
      identifier
    }

    return restClient.post(`/api/surveys/${surveyId}/questions`, params).then(({ data: { questions, answers } }) => {
      questionsActions.setAll(questions)
      answersActions.setAll(answers)
    })
  }

  return {
    load,
    persistQuestions,
    ...factoryActions,
  }
}

export { reducer, actions }
export default { reducer, actions }
