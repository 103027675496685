import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import actionFactory from 'Shared/hooks/actionFactory'

const adapter = createEntityAdapter()

const reducer = createSlice({
  name: 'answers',
  initialState: adapter.getInitialState(),
  reducers: {
    upsert: adapter.upsertOne,
    add: adapter.addOne,
    set: adapter.setOne,
    setAll: adapter.setAll,
    remove: adapter.removeOne,
  },
}).reducer

const actions = (dis, store, restClient) => actionFactory(dis, store, 'answer', {}, {})

export { reducer, actions }
export default { reducer, actions }
