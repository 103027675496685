import { Provider } from 'react-redux'
import PageContainer from 'FlowOverview/components/PageContainer'
import store from '../store'

export default function FlowOverview(props) {
  return (
    <Provider store={store}>
      <PageContainer {...props} />
    </Provider>
  )
}
