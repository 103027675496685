export default (dis, store, client) => {
  function load(id) {
    return client.get(`/api/surveys/${id}`)
      .then(({ data }) => {
        dis({ type: 'builder/upsert', payload: { loaded: true }})
        dis({ type: 'survey/upsert', payload: data })
      })
  }

  function upsert(payload) {
    dis({ type: 'survey/upsert', payload })
  }

  function save() {
    const survey = store.getState().survey
    const { id } = survey

    return client.put(`/api/surveys/${id}`, { survey })
      .then(({ data }) => {
        dis({ type: 'survey/upsert', payload: data})
      })
  }

  function launch() {
    const survey = store.getState().survey
    const { id } = survey

    return client.post(`/api/surveys/${id}/launch`)
  }

  return {
    load,
    upsert,
    save,
    launch
  }
}
