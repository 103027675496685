import { useAct } from 'FlowBuilder/store'
import SurveyAction from './SurveyAction'
import './Sidebar.scoped.scss'

const KIND_MAPPINGS = {
  'redirect': 'Redirect'
}

// first find the one where isStart = true and then follow the nextActionId, and then any not included, put at end
function orderActions(actions) {
  const startAction = actions.find(a => a.isStart)
  if (!startAction) return actions

  const ordered = [startAction]
  let nextAction = actions.find(a => a.id === startAction.nextActionId)
  while (nextAction) {
    ordered.push(nextAction)
    nextAction = actions.find(a => a.id === nextAction.nextActionId)
  }

  const notIncluded = actions.filter(a => !ordered.find(o => o.id === a.id))
  ordered.push(...notIncluded)

  return ordered
}

export default function Sidebar() {
  const actions = useSel(s => _.values(s.actions.entities) || [])
  const orderedActions = orderActions(actions)

  return <div className="flow-sidebar">
    {orderedActions.map(action => <Action key={action.id} action={action} />)}
  </div>
}

function Action({ action }) {
  const act = useAct()
  const { name, kind } = action
  const selectedActionId = useSel(s => s.local.selectedActionId)
  const selected = selectedActionId === action.id

  if (kind === 'survey') {
    return <SurveyAction action={action} />
  }

  return <div className={`box cursor-pointer ${selected ? 'dark-border' : ''} margin-bottom`} onClick={() => act.builder.selectAction(action.id)}>
    <p className='title'>{KIND_MAPPINGS[kind] || name}</p>
  </div>
}
