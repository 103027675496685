export default function RedirectAction({ actionId }) {
  const action = useSel(s => s.taker.flow.actions.find(a => a.id === actionId) || {})
  const { config } = action

  useEffect(() => {
    window.location.href = config?.redirectUrl || '#'
  }, [])

  return <div>
    Redirecting...
  </div>
}
