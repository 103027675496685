import * as React from 'react'

function SvgCircleFacebook(props) {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="24" cy="24" r="24" fill="#0865FE"/>
      <path d="M21 20H18V24H21V36H26V24H29.642L30 20H26V18.333C26 17.378 26.192 17 27.115 17H30V12H26.192C22.596 12 21 13.583 21 16.615V20Z" fill="white"/>
    </svg>
  )
}

export default SvgCircleFacebook
