import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import TextSingleLine from 'Survey/components/question-types/TextSingleLine'
import MultipleChoice from 'Survey/components/question-types/MultipleChoice'
import MultipleSelect from 'Survey/components/question-types/MultipleSelect'
import Score from 'Survey/components/question-types/Score'
import PhoneNumber from 'Survey/components/question-types/PhoneNumber'
import OptInRadio from 'Survey/components/question-types/OptInRadio'
import SafeRenderHtml from 'CampaignVolunteer/components/SafeRenderHtml'
import phone from 'phone'

const questionTypeComponents = {
  'text': TextSingleLine,
  'multiple_choice': MultipleChoice, // radios
  'multiple_select': MultipleSelect, // checkboxes
  'score': Score,
  'ranking': Score,
  'name': TextSingleLine,
  'email': TextSingleLine,
  'postcode': TextSingleLine,
  'phone_number': PhoneNumber,
  'instruction': () => <div />,
  'opt_in_radio': OptInRadio,
  'unknown': () => <div>Unknown</div>,
}

export default function TakeSurveyForm({ surveyId, pageNumber, onSubmit }) {
  const questions = useSel(s => {
    const allQuestions = _.values(s.questions.entities).filter(q => q.surveyId === surveyId && q.active)
    const pageQuestions = allQuestions.filter(q => q.pageNumber === pageNumber)
    return _.orderBy(pageQuestions, 'digitOrder')
  })

  const pageCount = useSel(s => {
    const allQuestions = _.values(s.questions.entities).filter(q => q.surveyId === surveyId && q.active)
    return _.max(allQuestions.map(q => q.pageNumber))
  })

  const validationSchema = Yup.object().shape(
    questions.reduce((acc, question) => {
      const { id, questionType, settings } = question

      let validationRule = Yup.string()

      if (settings?.required) {
        validationRule = validationRule.required('Please enter a response')
      }

      switch (questionType) {
        case 'email':
          validationRule = validationRule.email('Please check your email address is correct')
          break

        case 'postcode':
          validationRule = validationRule.matches(/^[A-Z0-9 ]+$/, 'Please check your postcode is correct')
          break

        case 'phone_number':
          validationRule = validationRule.test(
            'is-valid-phone',
            'Please check your phone number is correct',
            (value) =>
              !value || phone(value).isValid || phone(value, { country: 'GB' }).isValid
          )
          break

        default:
          break
      }

      acc[id] = validationRule
      return acc
    }, {})
  )

  const initialValues = questions.reduce((acc, q) => {
    acc[q.id] = ''
    return acc
  }, {})

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="form">
            {questions.map((q) => (
              <Question key={q.id} question={q} />
            ))}
            <button
              type="submit"
              className="submit-button button primary"
              disabled={isSubmitting}
            >
              {pageNumber === pageCount ? 'Submit' : 'Next'}
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

function Question({ question }) {
  const QuestionType = questionTypeComponents[question.questionType] || questionTypeComponents.unknown

  if (!QuestionType) {
    throw new Error(`No question type component found for ${question.questionType}`)
  }

  const answers = useSel(s => _.values(s.answers.entities).filter(a => a.questionId === question.id))
  const label = String(question.question).replaceAll("\n", "<br />")
  const fieldName = question.id

  return (
    <div className="field">
      {question.questionType !== 'instruction' && <label>{label}</label>}
      {question.questionType === 'instruction' && <SafeRenderHtml html={label} />}
      <Field name={fieldName}>
        {({ field, form }) => {
          const setResponse = (value) => form.setFieldValue(field.name, value)
          const response = field.value

          return (
            <QuestionType
              name={field.name}
              answers={answers}
              response={response}
              setResponse={setResponse}
              settings={question.settings}
              placeholder={question.question}
              disabled={form.isSubmitting}
            />
          );
        }}
      </Field>
      <ErrorMessage
        name={fieldName}
        component={({ children }) => {
          return <p className="smaller error no-margin-bottom">{children}</p>
        }}
      />
    </div>
  )
}
