import CodeMirrorEditor from 'Shared/components/CodeMirrorEditor'

const HtmlBlock = ({ block }) => {
  const act = useAct()

  const handleEditorChange = (value) => {
    console.log('CodeMirror onChange value:', value)
    act.email.blocks.updateParameters(block.id, {text: value})
  }

  return (
    <div id={`block_${block.id}`}>
      <CodeMirrorEditor
        value={block.parameters.text}
        onChange={handleEditorChange}
      />
    </div>
  )
}

export default HtmlBlock
