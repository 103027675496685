import PageContainer from './PageContainer'

export default function FlowWizard(params) {
  return (
    <ErrorBoundary>
      <PageContainer {...params} />
    </ErrorBoundary>
  )
}

