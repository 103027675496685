import { useAct } from 'FlowBuilder/store'
import './TopNav.scoped.scss'

export default function TopNav() {
  const act = useAct()
  const navItem = useSel(s => s.local.navItem)
  const selectedAction = useSel(s => s.actions.entities[s.local.selectedActionId])
  const surveyActionId = useSel(s => s.builder.flow?.actions?.find(a => a.kind === 'survey')?.id)
  const canPreview = selectedAction?.kind !== 'redirect' // initially, just redirects can't be previewed

  useEffect(() => {
    if (!canPreview) {
      act.local.setNav('build')
    }
  }, [JSON.stringify(selectedAction)])

  function changeTab(to) {
    if (!canPreview) {
      if (surveyActionId) {
        act.builder.selectAction(surveyActionId)
      }
      act.local.setNav('build')
    } else {
      act.local.setNav(to)
    }
  }

  return <div className='row'>
    <div className='tabs column'>
      <div className={`tab ${navItem === 'build' ? 'selected' : ''}`} onClick={() => act.local.setNav('build')}>Build</div>
      <div className={`tab ${navItem === 'setup' ? 'selected' : ''}`} onClick={() => act.local.setNav('setup')}>Setup</div>
      <div className={`tab ${navItem === 'preview' ? 'selected' : ''}`} onClick={() => changeTab('preview')}>Preview</div>
    </div>
  </div>
}
