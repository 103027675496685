import { useAct } from 'FlowBuilder/store'
import Button from 'Shared/components/Button'
import Loading from 'Shared/components/Loading'
import ServerWaitButton from 'Shared/components/ServerWaitButtonStandalone'

export default function Setup({}) {
  const act = useAct()

  const {id, settings} = useSel(s => s.builder?.flow || {})

  if(!settings) {
    return <Loading />
  }

  function settingUpdater(key) {
    return (e) => {
      act.builder.updateFlowSetting(key, e.target.value)
    }
  }


  return <div className="box">
    <form className="form">
      <h2 className="no-margin-top">Setup</h2>
      <p>Update the details of this flow.</p>

      <div className="field">
        <label>Share title</label>
        <input type="text" name="share_title" value={settings.shareTitle} onChange={settingUpdater('shareTitle')} />
      </div>

      <div className="field">
        <label>Share description</label>
        <input type="text" name="share_description" value={settings.shareDescription} onChange={settingUpdater('shareDescription')} />
      </div>

      <div className="field">
        <label>Share image URL</label>
        <input type="text" name="share_image_url" value={settings.shareImageUrl} onChange={settingUpdater('shareImageUrl')} />
      </div>

      <div className="text-align-right">
        <ServerWaitButton primary onClick={act.builder.persistFlowSettings}>Update setup</ServerWaitButton>
      </div>
    </form>
  </div>
}
