import { Backdrop } from '@material-ui/core'
import './Modal.scoped.scss'

const Modal = ({ open, title, onCancel, children, autoWidth = false, autoHeight = false, extraClass }) => {
  const classNames = ['modal-root']
  if (autoWidth) classNames.push('auto-width')
  if (autoHeight) classNames.push('auto-height')
  if (extraClass) classNames.push(extraClass)

  return (
    <Backdrop open={open} style={{ zIndex: 1000 }}>
      <div maxwidth="md" className={classNames.join(' ')}>
        <div className="modal-title-bar">
          <h1>{title}</h1>
          <button className="button naked close-modal" onClick={onCancel}><SvgIconsClose className='no-margin' /></button>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </Backdrop>
  )
}

export default Modal
