import Toggle from 'react-toggle'
import './SubscriptionList.scoped.scss'
import restClient from 'Shared/hooks/restClient'

function Subscription({ endpoint, id, value, kind, name, updatedAt, label }) {
  const [checked, setChecked] = useState(value)
  const [saving, setSaving] = useState(false)
  const handleChange = (e) => {
    setSaving(true)
    setChecked(e.target.checked)
    restClient
      .put(`${endpoint}/${id}`, { subscription: { subscribed: e.target.checked } })
      .then((r) => {
        setChecked(r.data.subscribed)
        setSaving(false)
      })
      .catch((e) => {
        console.error(e)
        setSaving(false)
      })
  }
  const formattedDate = new Date(updatedAt).toLocaleDateString('default')
  return (
    <>
      <span>{label}</span>
      <Toggle name={kind} checked={checked} disabled={saving} onChange={handleChange} />
      <span className="ml-2 text-sm text-gray-600">Last changed: {formattedDate}</span>
    </>
  )
}

export default function SubscriptionList({subscriptions, endpoint}) {
  return (
    <div className="subscription-list">
      {subscriptions.map((subscription, i) => <Subscription endpoint={endpoint} {...subscription} />)}
    </div>
  )
}
