import { useAct } from 'FlowBuilder/store'
import questionTypes from '../ActionForms/Survey/question-types'
import './AddQuestion.scoped.scss'

function getIcon(questionType) {
  switch (questionType) {
    case "instruction":
      return <SvgIconsQuote width="40" height="40" color="#F0C266" />
    case "text":
      return <SvgIconsText />
    case "multiple_choice":
      return <SvgIconsCircleTick />
    case "name":
      return <SvgIconsText />
    case "email":
      return <SvgIconsText />
    case "postcode":
      return <SvgIconsText />
    default:
      return null
  }
}

function getDescription(questionType) {
  switch (questionType) {
    case "instruction":
      return 'Add an instruction'
    case "text":
      return 'Add a question with a text answer'
    case "multiple_choice":
      return 'Choose one of the multiple options'
    case "name":
      return 'Add a name'
    case "email":
      return 'Add an email'
    case "postcode":
      return 'Add a postcode'
    default:
      return null
  }
}

export default function AddQuestion() {
  const act = useAct()
  const actionId = useSel(s => s.local.selectedActionId)
  const surveyId = useSel(s => s.builder.flow?.actions?.find(a => a.id === actionId)?.surveyId)
  const pageNumber = useSel(s => s.local.modal.pageNumber)

  function addQuestion(questionType) {
    act.questions.add({ questionType, surveyId, pageNumber, settings: {} })
    act.local.closeModal()
  }

  const buttons = Object.keys(questionTypes).map((questionType) => ({
    icon: questionTypes[questionType].icon,
    type: questionType,
    label: questionTypes[questionType].name,
  }))

  return <div className="grid-cols-3 gap-10">
    {
      buttons.map(({ type, label }) => (
        <div className="question-button" onClick={() => addQuestion(type)}>
          <div className='box cursor-pointer'>
            {getIcon(type)}
            <p className="label">{label}</p>
            <p className="description">{getDescription(type)}</p>
          </div>
        </div>
      ))
    }
  </div>
}
