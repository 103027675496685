export default function DonateForm() {
  return <div className="donation-form">
    <div className="frequency-toggle">
      <button className="frequency-btn" type="button">One-time</button>
      <button className="frequency-btn active" type="button">
        <span className="heart">♥</span> Monthly
      </button>
    </div>

    <div className="amount-grid">
      <button className="amount-btn" type="button">£20</button>
      <button className="amount-btn" type="button">£40</button>
      <button className="amount-btn selected" type="button">£75</button>
      <button className="amount-btn" type="button">£190</button>
      <button className="amount-btn" type="button">£400</button>
      <button className="amount-btn" type="button">£750</button>
    </div>

    <div className="custom-amount">
      <div className="input-group">
        <span className="currency">£</span>
        <input type="number" value="75" min="1" />
        <select className="currency-select">
          <option value="GBP">GBP</option>
        </select>
      </div>
    </div>

    <button className="donate-btn" type="button">Donate</button>
  </div>
}
