import './Text.scoped.scss'

export default function TextSingleLine({response, setResponse, type = "text", placeholder = "Type an answer...", disabled = false}) {
  const handleChange = (event) => {
    setResponse(event.target.value);
  }

  return (
    <div className="answers grow">
      <input
        placeholder={placeholder}
        onChange={handleChange}
        type={type}
        disabled={disabled}
        value={response || ''} />
    </div>
  )
}
