import Modal from 'Shared/components/Modal/Modal'
import OrganisationPicker from './OrganisationPicker'

export default function OrganisationPickerModal({ open, hide, onPick }) {
  const [resetTrigger, setResetTrigger] = useState(0)

  useEffect(() => {
    if (open) {
      setResetTrigger((prev) => prev + 1)
    }
  }, [open])


  const handlePick = (id, name) => {
    onPick(id, name)
    hide()
  }
  return (
    <Modal title="Search for an organisation" open={open} onCancel={hide} autoWidth extraClass="vw-75">
      <OrganisationPicker onPick={handlePick} key={resetTrigger} />
    </Modal>
  )
}

