import { createSlice } from '@reduxjs/toolkit'

import { actions as actionActionsF } from './actions'
import { actions as localActionsF } from './local'
import { actions as surveyActionsF } from './surveys'

const reducer = createSlice({
  name: 'builder',
  initialState: {
    templateFields: {}
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
    setTemplateField: (s, a) => {
      s.templateFields[a.payload.slug] = a.payload.value
    },
    updateFlowSetting: (s, a) => {
      s.flow.settings[a.payload.key] = a.payload.value
    },
  },
}).reducer

const actions = (dis, store, restClient) => {
  const actionActions = actionActionsF(dis, store, restClient)
  const localActions = localActionsF(dis, store, restClient)
  const surveyActions = surveyActionsF(dis, store, restClient)

  function load(flowId) {
    return restClient.get(`/api/flows/${flowId}`)
      .then(({ data: flow }) => {
        // Put anything editable in a different part of the state
        const templateFields = flow.templateFields
        dis({ type: 'builder/upsert', payload: { flow, templateFields } })
        flow.actions.forEach(a => {
          if (a.kind === 'survey') {
            surveyActions.load(a.surveyId)
          }
        })
        if (flow.actions.length === 0) {
          localActions.setModal({ kind: 'wizard' })
        } else {
          dis({ type: 'actions/setAll', payload: flow.actions })
          selectAction(flow.actions.find(a => a.isStart)?.id)
        }

        localActions.setDirty(false)
      })
  }

  function loadMergeTags() {
    // create a new axios client which bypasses the case conversion - we want to
    // load merge tags in snake case
    return restClient.get(`/api/render/available_merge_tags`)
      .then(({ data: mergeTags }) => {
        dis({ type: 'builder/upsert', payload: { mergeTags } })
      })
  }

  function selectAction(actionId, { pageNumber = 1 } = {}) {
    dis({ type: 'local/upsert', payload: { selectedActionId: actionId, selectedPageNumber: pageNumber } })
  }

  function selectQuestion(questionId) {
    dis({ type: 'local/upsert', payload: { selectedQuestionId: questionId } })
  }

  function setTemplateField(payload) {
    localActions.setDirty(true)
    dis({ type: 'builder/setTemplateField', payload })
  }

  function updateFlowSetting(key, value) {
    dis({ type: 'builder/updateFlowSetting', payload: { key, value } })
  }

  function persistAll() {
    const state = store.getState()
    const actions = state.builder.flow.actions

    const promises = []
    actions.forEach(action => {
      if (action.kind === 'survey') {
        promises.push(surveyActions.persistQuestions(action.surveyId))
      } else {
        promises.push(actionActions.persist(action.id))
      }
    })
    promises.push(persistTemplateFields())
    promises.push(persistFlowSettings())

    return Promise.all(promises)
      .then(() => localActions.setDirty(false))
  }

  function persistTemplateFields() {
    const state = store.getState()
    const templateFields = state.builder.templateFields
    const flowId = state.builder.flow.id

    return restClient.put(`/api/flows/${flowId}`, { templateFields })
  }

  function persistFlowSettings() {
    const state = store.getState()
    const settings = state.builder.flow.settings
    const flowId = state.builder.flow.id

    return restClient.put(`/api/flows/${flowId}`, { settings })
  }

  return {
    load,
    loadMergeTags,
    selectAction,
    selectQuestion,
    setTemplateField,
    updateFlowSetting,
    persistTemplateFields,
    persistFlowSettings,
    persistAll,
  }
}

export { reducer, actions }
export default { reducer, actions }
