import { useAct } from 'FlowTaker/store'
import ShareForm from 'FlowBuilder/components/ActionViews/Share/ShareForm'
import TemplateRender from 'FlowBuilder/components/ActionViews/TemplateRender'

export default function ShareAction({ actionId, surveyId }) {
  const act = useAct()
  const bodyHtml = useSel(s => s.taker.flow.template.body)
  const publicUrl = useSel(s => s.taker.flow.publicUrl)
  const context = useSel(s => s.taker.context)
  const action = useSel(s => s.taker.flow.actions.find(a => a.id === actionId) || {})

  const handleSubmit = () => {
    return act.taker.completeAction({ actionId })
  }

  const componentMap = useMemo(() => ({
    TakeSurveyForm: <>
      <ShareForm
        action={action}
        onSubmit={handleSubmit}
        publicUrl={publicUrl}
      />
    </>,
  }), [surveyId])

  return <div>
    <TemplateRender htmlContent={bodyHtml} componentMap={componentMap} context={context} />
  </div>
}
