import { Grid, Input } from '@mui/material'
import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import Card from 'Shared/components/Card/Card'
import PillEditor from 'Shared/components/PillEditor/PillEditor'
import { Field, Formik } from 'formik'
import React from 'react'
import { create } from 'react-modal-promise'

export default function CannedResponse({ response, onSave }) {
  const act = useAct()
  const { mergeTags } = useSel((s) => s.campaignBuilder)

  const onDestroy = () => {
    create(({ isOpen, onResolve, onReject }) => (
      <BackdropModal title="Delete canned response" onCancel={onReject} open={isOpen}>
        <div className="wizard">
          <div className="step">
            <h3>Do you really want to delete this canned response?</h3>
            <button
              onClick={onResolve}
              type="button"
              className="red button margin-right no-margin-left"
            >
              Delete
            </button>
            <button onClick={onReject} type="button" className="primary button">
              Cancel
            </button>
          </div>
        </div>
      </BackdropModal>
    ))()
      .then(() => {
        act.cannedResponses.delete(response.id).then(onSave)
      })
      .catch(() => {})
  }
  return (
    <Formik
      initialValues={{
        name: response.name,
        body: response.body,
      }}
      enableReinitialize
      onSubmit={(values) => {
        const action = response.id
          ? act.cannedResponses.edit(response.id, values)
          : act.cannedResponses.create(values)

        return action.then(onSave)
      }}
    >
      {({ isSubmitting, handleSubmit, values, setFieldValue, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Card interactive noRenderEmpty>
            {!response.id && <p className='bold no-margin-top'>Add new canned response</p>}
            <Grid container>
              <div className="field">
                <label>Name</label>
                <Field name="name" type="text" />
              </div>
              <div className="field">
                <label>Body</label>
                <div className="custom-input">
                  <PillEditor
                    initialValue={values.body}
                    onChange={(body) => setFieldValue('body', body)}
                    availableTags={mergeTags}
                    singleLine
                    allowNewLines
                    condensed
                  />
                </div>
              </div>
              <Grid container>
                <button
                  className="button primary margin-right"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {response.id ? 'Save' : 'Add'}
                </button>
                {!response.id && (
                  <button className="button secondary" type="button" onClick={onSave}>
                    Cancel
                  </button>
                )}
                {!!response.id && (
                  <button className="button red" type="button" onClick={onDestroy}>
                    Delete
                  </button>
                )}
              </Grid>
            </Grid>
          </Card>
        </form>
      )}
    </Formik>
  )
}
