import { store } from 'FlowBuilder/store'
import { Provider } from 'react-redux'
import PageContainer from 'FlowBuilder/components/PageContainer'

export default function FlowBuilder(params) {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <PageContainer {...params} />
      </ErrorBoundary>
    </Provider>
  )
}

