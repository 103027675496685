import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { useStore } from 'react-redux'
import restClient from 'Shared/hooks/restClient'

import actions from 'FlowBuilder/store/actions'
import answers from 'FlowBuilder/store/answers'
import builder from 'FlowBuilder/store/builder'
import local from 'FlowBuilder/store/local'
import questions from 'FlowBuilder/store/questions'
import surveys from 'FlowBuilder/store/surveys'

// the reducers and actions for each slice are stored together in slices/sliceName.js
const slices = {
  actions,
  answers,
  builder,
  local,
  questions,
  surveys
}

const store = configureStore({
  middleware: [...getDefaultMiddleware()],
  reducer: _.mapValues(slices, (slice) => slice.reducer),
  devTools: {
    name: 'Flow builder',
  },
})

const useAct = () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () => _.mapValues(slices, (fn) => {
      return fn.actions(dis, store, restClient)
    }),
    []
  )
}

export { store, useAct }
