export default function renderContext({ flow, flowTaker }) {
  const templateFields = flow.template.fields || []
  const templateFieldsValues = flow.templateFields

  const transformedTemplateFields = _.mapValues(templateFieldsValues, (value, key) => {
    const field = templateFields.find(f => _.camelCase(f.slug) === key)
    if (!field) return value

    if (field.image) {
      return `<img src="${value}" alt="${field.label}" />`
    }

    return value
  })

  const currentActionKind = flow.actions.find(a => a.id === flowTaker.currentActionId)?.kind
  const flowFields = { ...flow.flowFields, currentActionKind }

  return {
    template: transformedTemplateFields,
    flow: flowFields
  }
}
