import './Header.scoped.scss'

import { useAct } from 'FlowBuilder/store'
import BackLink from 'Shared/components/BackLink'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import TopNav from './TopNav'

export default function Header({ }) {
  const act = useAct()

  const { name, publicUrl } = useSel((s) => s.builder?.flow || {})
  const isDirty = useSel((s) => s.local.isDirty)

  const backLink = '/admin/flows'

  return (
    <>
      <header>
        <div>
          <div className="flex-center gap-10">
            <BackLink href={backLink} />{' '}
            <h1>{name}</h1>
          </div>
        </div>

        <div className="actions flex-center gap-12">
          <a className={`button tertiary ${isDirty ? 'disabled' : ''}`} target='_blank' href={isDirty ? '' : `${publicUrl}?forget=1`} rel="noreferrer">View</a>
          <ServerWaitButton className='button red' onClick={() => act.builder.load(id)} disabled={!isDirty}>Discard changes</ServerWaitButton>
          <ServerWaitButton className='button primary' onClick={act.builder.persistAll}>Save</ServerWaitButton>
        </div>
      </header>
      <TopNav />
    </>
  )
}
