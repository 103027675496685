import PhoneInput from 'react-phone-input-international'
import 'react-phone-input-international/lib/style.css'

export default function PhoneNumber({ response, setResponse, type = "text", placeholder = "Type an answer...", disabled = false }) {

  return (
    <div className="answers grow">
      <PhoneInput
        placeholder={placeholder}
        country='gb'
        preferredCountries={['gb']}
        value={response || ''}
        autoFormat={false}
        disableCountryCode
        disabled={disabled}
        onChange={setResponse}
        inputStyle={{ width: '240px' }}
      />
    </div>
  )
}
