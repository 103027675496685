import pluralize from 'pluralize'

export default (dis, store, entity, initialData = {}, extraActions = {}, { afterChange = () => {}} = {}) => {
  function add(data = {}) {
    const id = uuidv4()
    dis({ type: `${pluralize(entity)}/upsert`, payload: { id, ...initialData, ...data } })
    afterChange()
    return id
  }

  function upsert(id, data) {
    dis({ type: `${pluralize(entity)}/upsert`, payload: { id, ...data } })
    afterChange()
  }

  function setAll(data) {
    dis({ type: `${pluralize(entity)}/setAll`, payload: data })
    afterChange()
  }

  function remove(id, parentFilter = {}) {
    dis({ type: `${pluralize(entity)}/remove`, payload: id })
    dis({ type: `${pluralize(entity)}/fixDigitOrder`, payload: { parentFilter } })
    afterChange()
  }

  function moveDigitOrder(id, digitOrder, parentFilter = {}) {
    const promise = dis({ type: `${pluralize(entity)}/moveDigitOrder`, payload: { id, digitOrder, parentFilter } })
    afterChange()
    return promise
  }

  const extraActionsResolved = _.mapValues(extraActions, v => v(dis, store, entity))

  return { add, upsert, setAll, remove, moveDigitOrder, ...extraActionsResolved }
}
