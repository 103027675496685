import './InstructionsTextarea.scoped.scss'

const InstructionsTextarea = ({ id, initialInstructions, act }) => {
  const handleChange = (e) => {
    const newInstructions = e.target.value
    act.scriptBuilder.questions.upsert(id, { instructions: newInstructions })
  }

  return (
    <>
      <label className="margin-top half" htmlFor={`instructions-${id}`}>Instructions</label>
      <div className="instructions-textarea">
        <textarea
          id={`instructions-${id}`}
          value={initialInstructions || ''}
          onChange={handleChange}
          rows={3}
          placeholder="Enter instructions here..."
        />
      </div>
    </>
  )
}

export default InstructionsTextarea
