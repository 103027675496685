import { parseISO, format } from 'date-fns'
import EmailPreview from 'Shared/components/EmailPreview'
import useEmailPreview from 'Shared/hooks/useEmailPreview'
import './EmailOverview.scoped.scss'
import './OverviewStructure.scoped.scss'
import StatsOverview from './StatsOverview'
import SendSampleEmail from 'CampaignBuilder/components/Steps/SendSample/SendSampleEmail'
import { useEffect, useState } from 'react'

import SimplePaginator from 'Shared/components/SimplePaginator'
import FloatingMenu from 'Shared/components/FloatingMenu/FloatingMenu'
import { useWindowSize } from 'usehooks-ts'
import { MOBILE_SCREEN_WIDTH } from 'Shared/constants'
import { formatPercent } from './utils/dateAndMathsUtils'

const ZEROED_STATS = {
  emailsCount: 0,
  deliveredCount: 0,
  bouncedCount: 0,
  openedCount: 0,
  clickedCount: 0,
  unsubscribedCount: 0,
  helpfulCount: 0,
  notHelpfulCount: 0,
}

export default function EmailOverview() {
  const campaignId = useSel((s) => s.local.campaignId)
  const campaignBuilder = useSel((s) => s.campaignBuilder)
  const search = useSel((s) => s.campaign?.search)
  const currentContentVariantId = useSel((s) => s.local.currentContentVariantId)

  let campaignStats = useSel((s) => s.campaign?.stats || [{}])

  // TODO: REMOVE when migrated
  // backwards compatibility with old stats saved in DB
  if (!_.isArray(campaignStats)) {
    campaignStats = [campaignStats]
  }

  const emailBlastId = useSel((s) => s.local.emailBlastId)
  const emailBlast = useSel((s) => s.campaign?.emailBlast)
  const [html, subjectLine] = useEmailPreview([], campaignId, emailBlastId, currentContentVariantId)
  const [sendSampleModalOpen, setSendSampleModalOpen] = useState(false)
  const canSendSample = !!emailBlast?.emailSenderId && !!campaignBuilder?.previewTargetId
  const act = useAct()
  const dis = useDis()
  const [overviewPage, setOverviewPage] = useState(1)

  useEffect(() => {
    dis({ type: 'campaignBuilder/upsert', payload: { previewTargetId: search?.id } })
    act.campaign.upsert({
      id: campaignId,
      emailBlast: emailBlast,
    })
  }, [search, emailBlast])

  const {
    emailsCount,
    deliveredCount,
    bouncedCount,
    openedCount,
    clickedCount,
    unsubscribedCount,
    helpfulCount,
    notHelpfulCount,
  } = _.reduce(
    campaignStats,
    (acc, stats) => _.mapValues(acc, (value, key) => value + stats[key]),
    {
      emailsCount: 0,
      deliveredCount: 0,
      bouncedCount: 0,
      openedCount: 0,
      clickedCount: 0,
      unsubscribedCount: 0,
      helpfulCount: 0,
      notHelpfulCount: 0,
    }
  )

  const size = useWindowSize()

  const sendingInProgress =
    emailBlast && emailBlast.finishedSendingAt === null
  const allFailed =
    !sendingInProgress && emailsCount > 0 && bouncedCount === emailsCount && deliveredCount === 0

  const menuItems = [
    { name: 'Summary', tag: 'summary' },
    { name: 'Analytics', tag: 'analytics' },
    { name: 'Links', tag: 'links' },
    { name: 'Preview', tag: 'preview' },
  ]
  if (sendingInProgress) {
    menuItems.unshift({ name: 'Progress', tag: 'progress' })
  }

  const stats = [
    { value: formatPercent(openedCount, emailsCount), label: 'Open rate' },
    { value: formatPercent(clickedCount, emailsCount), label: 'Click rate' },
    { value: unsubscribedCount || 0, label: 'Unsubscribes' },
    { value: formatPercent(bouncedCount, emailsCount), label: 'Bounce rate' },
  ]

  return (
    <div className="overview">
      <div className="left">
        {size.width >= MOBILE_SCREEN_WIDTH && <FloatingMenu items={menuItems} />}
        {size.width < MOBILE_SCREEN_WIDTH && (
          <SimplePaginator
            totalPages={4}
            currentPage={overviewPage}
            onChange={setOverviewPage}
            labels={menuItems.map((item) => item.name)}
            classes="margin-bottom"
          />
        )}
      </div>
      <div className="right">
        <StatsOverview
          overviewPage={overviewPage}
          targetsCount={emailsCount}
          entityBlast={emailBlast}
          sendingInProgress={sendingInProgress}
          allFailed={allFailed}
          stats={stats}
          helpfulCount={helpfulCount}
          notHelpfulCount={notHelpfulCount}
        />

        {((Array.isArray(campaignStats) && campaignStats[0]?.links?.length > 0) ||
          (!Array.isArray(campaignStats) && campaignStats?.links?.length > 0)) && (
          <>
            {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 3) && (
              <div id="links" className="box margin-top margin-bottom">
                <h2 className="no-margin-top">Links</h2>
                <ul>
                  {(Array.isArray(campaignStats)
                    ? campaignStats[0]?.links
                    : campaignStats?.links
                  ).map(({ url, count }) => (
                    <li key={url} className="break-word">
                      <strong>{count}</strong> - {url}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}

        {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 4) && (
          <>
            {html && (
              <div id="preview" className="box margin-top preview-container">
                <div className="header">
                  <h2 className="no-margin-top">Preview</h2>
                  {canSendSample && (
                    <div className="actions">
                      <button
                        type="button"
                        className="button primary"
                        onClick={() => (canSendSample ? setSendSampleModalOpen(true) : null)}
                      >
                        Send sample
                      </button>
                      <SendSampleEmail
                        open={sendSampleModalOpen}
                        onlyAllowEmail={true}
                        renderedBody={html}
                        close={() => setSendSampleModalOpen(false)}
                      />
                    </div>
                  )}
                </div>

                <EmailPreview
                  html={html}
                  subjectLine={subjectLine}
                  senderName={emailBlast?.emailSender?.name}
                  searchName={search?.name}
                  inline={true}
                  showHeader
                  noContainerHeight={true}
                  iframeStyle={{
                    transform: 'scale(0.7)',
                    width: '100%',
                    border: '2px solid grey',
                    padding: '10px',
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
