import Step from './Step'
import PillEditor from 'Shared/components/PillEditor/PillEditor'

import './EmailSubject.scoped.scss'

const SUBJECT_MAX_LENGTH = 150

function EmailSubjectShortDescriptor({ complete, subject, preview }) {
  return (
    <div>
      {complete && (
        <div>
          <p>{subject}</p>
          <p className="no-margin-bottom no-margin-top italic incomplete">{preview}</p>
        </div>
      )}
      {!complete && (
        <p className="incomplete no-margin-bottom">
          What&rsquo;s the first thing the recipient sees?
        </p>
      )}
    </div>
  )
}

const EmailSubject = () => {
  const act = useAct()
  const subjectLines = useSel((s) => s.campaign?.emailBlast?.subjectLines || [])
  const currentSubjectLineId = useSel((s) => s.local.currentSubjectLineId)
  const complete = _.every(subjectLines, (sl) => !!sl.subjectLine)
  const enableAbTesting = useSel(
    (s) => window.ENV.FEATURES['email_ab_testing'] && !s.campaign.isWorkflowControlled
  )

  function setCurrentSubjectLineId(id) {
    act.local.upsert({ currentSubjectLineId: id })
  }

  useEffect(() => {
    if (subjectLines.length === 1) {
      setCurrentSubjectLineId(subjectLines[0]?.id)
    }
  }, [])

  if (subjectLines.length === 1) {
    var { subjectLine, previewLine } = subjectLines[0]
  } else {
    var subjectLine = `${subjectLines.length} subject lines`
    var previewLine = ''
  }

  return (
    <Step
      name="Subject"
      editor="subject"
      complete={complete}
      canClickFinish={complete}
      shortDescriptor={
        <EmailSubjectShortDescriptor
          complete={complete}
          subject={subjectLine}
          preview={previewLine}
        />
      }
    >
      <div className="row">
        <div className="column two-thirds">
          {subjectLines.map((sl, idx) => (
            <SubjectLineForm
              key={sl.id}
              id={sl.id}
              idx={idx}
              editing={currentSubjectLineId === sl.id}
              onEdit={() => setCurrentSubjectLineId(sl.id)}
              canRemove={subjectLines.length > 1}
            />
          ))}

          {enableAbTesting && (
            <div>
              <button className="button secondary" onClick={act.campaign.addSubjectLine}>
                <SvgIconsExperiment /> Add Version
              </button>
            </div>
          )}
        </div>

        <div className="column third">
          <div className="box purple-background white-text">
            <h2>Tips for a great subject line</h2>
            <ul>
              <li>Be concise</li>
              <li>Personalise</li>
              <li>Ask a question</li>
              <li>Use emojis</li>
            </ul>
          </div>
        </div>
      </div>
    </Step>
  )
}

export default EmailSubject

function SubjectLineForm({ id, idx, onEdit, editing = false }) {
  const act = useAct()
  const { mergeTags } = useSel((s) => s.campaignBuilder)

  const updateSubject = (subjectLine) =>
    act.campaign.updateSubjectLine({ id, subjectLine })
  const updatePreview = (previewLine) =>
    act.campaign.updateSubjectLine({ id, previewLine })
  const hasMultipleVersions = useSel(
    (s) => (s.campaign.emailBlast.subjectLines?.length || 0) > 1
  )
  const canRemove = hasMultipleVersions

  const { subjectLine, previewLine } = useSel((s) =>
    s.campaign.emailBlast.subjectLines.find((sl) => sl.id === id)
  )

  const View = (
    <>
      {hasMultipleVersions && (
        <div className="align-center">
          <strong>Version {idx + 1}</strong>{' '}
          <SvgIconsEdit className="edit-icon" onClick={onEdit} color="#888" />
        </div>
      )}
      <p>
        <strong>Subject line:</strong> {subjectLine}
      </p>
      {previewLine && (
        <p>
          <strong>Preview line:</strong> <span className="italic">{previewLine}</span>
        </p>
      )}
    </>
  )

  const calculateLength = (subjectLine) => {
    const cleanedText = (subjectLine || '').replace(/{{.*?}}|{%.*?%}/g, '')
    return cleanedText.length
  }

  const Edit = (
    <>
      {hasMultipleVersions && (
        <div className="margin-bottom align-center">
          <strong>Version {idx + 1}</strong>
        </div>
      )}
      <div className="field">
        <div className="character-counter">
          <label className="light">Subject line</label>
          <p className="count">
            <span className="ellipsis narrower"></span> {calculateLength(subjectLine)} /{' '}
            {SUBJECT_MAX_LENGTH} characters
          </p>
        </div>
        <PillEditor
          initialValue={subjectLine}
          onChange={updateSubject}
          availableTags={mergeTags}
          enableEmojis={true}
          condensed
          singleLine
        />
      </div>

      <div className="field">
        <div className="character-counter">
          <label>Preview line (optional)</label>
          <p className="count">
            <span className="ellipsis narrower"></span> {calculateLength(previewLine)} /{' '}
            {SUBJECT_MAX_LENGTH} characters
          </p>
        </div>
        <PillEditor
          initialValue={previewLine}
          onChange={updatePreview}
          availableTags={mergeTags}
          enableEmojis={true}
          condensed
          singleLine
        />
      </div>
    </>
  )

  return (
    <div className="box grey-background margin-bottom subject-line">
      <div className="row">
        <div className="column remaining">{editing ? Edit : View}</div>
        {canRemove && (
          <div className="column align-vertical-center margin-left">
            <div>
              <SvgIconsTrash
                className="delete-icon"
                onClick={() => act.campaign.removeSubjectLine(id)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
