import { createSlice } from '@reduxjs/toolkit'
import { generateEntitySlices } from 'Shared/sliceHelpers'

const flowSlice = createSlice({
  name: 'flow',
  initialState: {
    name: '',
    stats: null,
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
}).reducer

const entitySliceNames = ['questions', 'answers']

const reducers = {
  flow: flowSlice,
  ...generateEntitySlices(entitySliceNames)
}

export default reducers
