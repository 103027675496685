export default function Button(props) {
  const classNames = ['button']
  if (props.extraClass) {
    classNames.push(props.extraClass)
  }
  const options = [
    'small-icon-button',
    'primary',
    'secondary',
    'tertiary',
    'green',
    'light',
    'dark',
    'red',
    'large',
    'medium',
    'small',
    'naked',
    'faded-50',
    'no-padding',
  ]
  options.forEach((key) => {
    if (props[key]) {
      classNames.push(key)
    }
  })

  return (
    <button
      disabled={props.disabled}
      className={classNames.join(' ')}
      onClick={props.onClick}
      type={props.type}
    >
      {props.children}
    </button>
  )
}
