import restClient from 'Shared/hooks/restClient'
import Wizard from './Wizard'
import './PageContainer.scoped.scss'

export default function PageContainer() {
  const [wizard, setWizard] = useState(null)
  const [wizards, setWizards] = useState([])

  useEffect(() => {
    restClient.get('/api/flows/available_wizards')
      .then(response => setWizards(response.data))
  }, [])

  return (
    <div>
      {!wizard && <ChooseWizard wizards={wizards} setWizard={setWizard} />}
      {wizard && <WizardForm wizard={wizard} key={wizard.slug} setWizard={setWizard} />}
    </div>
  )
}

function ChooseWizard({ wizards, setWizard }) {
  return <Wizard wizards={wizards} setWizard={setWizard} />
}

function WizardForm({ wizard, setWizard }) {
  const [fields, setFields] = useState(() => {
    return wizard.fields.reduce((acc, field) => {
      if (field.questionType === 'checkbox' && field.options) {
        const defaultOption = field.options.find(option => option.default);
        acc[field.slug] = defaultOption ? defaultOption.value : ''
      }
      if (field.questionType === 'select' && field.options) {
        acc[field.slug] = field.options[0].value
      }
      return acc
    }, {})
  })

  function setField(slug, value) {
    setFields(d => ({ ...d, [slug]: value }))
  }

  function create() {
    restClient.post('/api/flows', { wizardSlug: wizard.slug, wizardFields: fields, name: 'New Flow' })
      .then(({ data }) => {
        const flowId = data.id
        window.location.href = `/admin/flows/${flowId}/build?nav=preview`
      })
  }

  const disabled = !_.every(wizard.fields, field => {
    // If field is not required, always return true
    if (field.required === false) return true;

    // For checkbox fields, an empty string is a valid state
    if (field.questionType === 'checkbox') {
      // fields[field.slug] will be either the selected value or an empty string
      return true;
    }

    // For all other field types, check for truthy values
    return !!fields[field.slug];
  })

  return (
    <div>
      <div className="header margin-left">
        <button className="button naked margin-bottom" onClick={() => setWizard(null)}><SvgIconsBack /></button>
        <h1 className="margin-bottom">{wizard.name}</h1>
        <button className="button secondary small margin-bottom" onClick={() => setWizard(null)}>Finish later</button>
      </div>
      <div className='row'>
        <div className='box form'>
          <h1>Page details</h1>
          <p className="margin-bottom double">Add some details to get started</p>
          {wizard.fields.map(field => (
            <div className='field shorten' key={field.slug}>
              <label>{field.label}</label>
              <div className="url-box">
                {field.preText && <p>{field.preText}</p>}
                {(field.questionType === 'text' || field.questionType === 'url') &&
                  <input type="text" value={fields[field.slug]} placeholder={field.placeholder} onChange={e => setField(field.slug, e.target.value)} />
                }
              </div>
              {field.questionType === 'paragraph' &&
                <textarea value={fields[field.slug]} placeholder={field.placeholder} onChange={e => setField(field.slug, e.target.value)} />
              }
              {field.description && <p className="text-small">{field.description}</p>}
              {field.questionType === 'select' &&
                <select
                  value={fields[field.slug]}
                  onChange={e => setField(field.slug, e.target.value)}
                >
                  <option value="" disabled>{field.placeholder || "Please select an option"}</option>
                  {field.options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              }
              {field.questionType === 'checkbox' &&
                field.options.map((option, index) => (
                  <div key={index} className="checkbox">
                    <input
                      type="checkbox"
                      id={option.value}
                      checked={fields[field.slug] === option.value}
                      onChange={e => setField(field.slug, e.target.checked ? option.value : '')}
                    />
                    <label htmlFor={option.value}>{option.label}</label>
                  </div>
                ))
              }
            </div>
          ))}

          <div className='field'>
            <button disabled={disabled} className='button primary' onClick={create}>Create Page</button>
          </div>
        </div>
      </div>
    </div>
  )
}
