import DonateForm from './DonateForm'
import TemplateRender from '../TemplateRender'
import Preview from '../Preview'

export default function SurveyActionView() {
  const wizardFields = useSel(s => s.builder.flow.wizardFields)
  const context = { template: wizardFields }
  const selectedActionId = useSel(s => s.local.selectedActionId)
  const { body, head, styles } = useSel(s => s.builder.flow.template)

  const componentMap = useMemo(() => ({
    TakeSurveyForm: <>
      <DonateForm />
    </>,
  }), [])

  return <Preview styles={styles} head={head}>
    <TemplateRender htmlContent={body} componentMap={componentMap} context={context} />
  </Preview>
}

