import restClient from 'Shared/hooks/restClient'
import Loading from 'Shared/components/Loading'
import SubscriptionList from 'Shared/components/SubscriptionList'


export default function TargetPreferences({}) {
  const [loading, setLoading] = useState(true)
  const [preferences, setPreferences] = useState(null)

  useEffect(() => {
    restClient
      .get(`/api/target/subscriptions`)
      .then((r) => {
        setPreferences(r.data)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])


  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <h1>Subscription Preferences</h1>
      {preferences.subscriptionStatuses.map(({organisationName, subscriptions}) => (
        <>
          <h3>{organisationName}</h3>
          <SubscriptionList subscriptions={subscriptions} endpoint="/api/target/subscriptions" />
        </>
      ))}
    </div>
  )
}
