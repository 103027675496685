import Description from 'SearchBuilder/components/Description'
import useCountTargets from 'CampaignBuilder/components/Steps/Audience/useCountTargets'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import restClient from 'Shared/hooks/restClient'
import { parseISO } from 'date-fns'
import { utcToZonedTime, format } from 'date-fns-tz'

export default function EditSchedule () {
  const act = useAct()
  const [newScheduledFor, setNewScheduledFor] = useState("")
  const [saveError, setSaveError] = useState(false)
  const [showValidation, setShowValidation] = useState(false)
  const [estimateLoaded, setEstimateLoaded] = useState(false)

  const { campaignId, searchId, scheduledFor, baseEndpoint, settings, entity } = useSel(s => {
    const { id, searchId, campaignType, textMessageBlast, emailBlast, settings } = s.campaign

    if (textMessageBlast) {
      var scheduledFor = textMessageBlast.scheduledFor
      var baseEndpoint = `/api/campaigns/${id}/text_message_blasts/${textMessageBlast.id}`
    } else if (emailBlast) {
      var scheduledFor = emailBlast.scheduledFor
      var baseEndpoint = `/api/campaigns/${id}/email_blasts/${emailBlast.id}`
    }
    const entity = textMessageBlast || emailBlast

    return { campaignId: id, searchId, campaignType, scheduledFor, baseEndpoint, settings, entity }
  })

  const allPermissions = useSel(s => s.currentUser.allPermissions || [])

  useEffect(() => {
    if (!searchId) return
    act.campaignBuilder.updateEstimates(searchId).then(() => setEstimateLoaded(true))
  }, [searchId])

  useEffect(() => {
    if (window.Cypress) {
      window.triggerScheduledForOnChange = v => handleChange({ target: { value: v } });
    }
  }, []);

  useEffect(() => {
    if (!scheduledFor) return
    // Parse the ISO string into a Date object
    const date = parseISO(scheduledFor);

    // Get the user's timezone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert the date to the user's timezone
    const dateInMyTimezone = utcToZonedTime(date, timeZone);

    // Format the date as an ISO string
    const newScheduledFor = format(dateInMyTimezone, "yyyy-MM-dd HH:mm");
    setNewScheduledFor(newScheduledFor); // Avoid race
  }, [scheduledFor])

  function callback(scheduled) {
    if (scheduled) {
      window.location.href = `/admin/campaigns?schedule_success=1`
    } else {
      act.campaign.load(campaignId)
      act.campaignBuilder.closeEditor()
      act.local.clearModal()
    }
  }

  let targetCount = useCountTargets()
  if (settings.testMode === 'ab') {
    const testSendSize = parseInt(settings.testSizePerVariant) * entity.contentVariants.length
    targetCount = Math.min(testSendSize, targetCount)
  }

  const canSend = allPermissions.includes('manage_campaigns') || (allPermissions.includes('manage_small_campaigns') && targetCount <= window.ENV.SMALL_CAMPAIGN_THRESHOLD)

  const handleChange = (e) => {
    setSaveError(false)
    setShowValidation(true)
    setNewScheduledFor(e.target.value);
  }

  function validate() {
    if (!newScheduledFor) {
      return ["Please select a date and time"]
    }
    const date = new Date(newScheduledFor);
    if (date <= new Date()) {
      return ["Scheduled date must be in the future"]
    }
    return []
  }
  const validationErrors = validate()
  const { baseCost, contactCost, creditRemaining } = useSel(s => s.campaignBuilder)
  const insufficientCredit = window.ENV.FEATURES['budget_enforcement'] && (baseCost + contactCost) > creditRemaining
  const disabled = !estimateLoaded || insufficientCredit || validationErrors.length > 0

  const handleScheduleClick = () => {
    const scheduledFor = (new Date(newScheduledFor)).toISOString()

    return restClient.post(`${baseEndpoint}/schedule`, { scheduledFor })
      .then(() => callback(true))
      .catch(() => setSaveError(true))
  }

  function handleUnscheduleClick() {
    return restClient.post(`${baseEndpoint}/unschedule`)
      .then(() => callback(false))
      .catch(() => setSaveError(true))
  }

  function handleSendForApprovalClick() {
    return act.sendBlast.sendForApproval(campaignId, { scheduledFor: newScheduledFor })
      .then(() => callback(true))
      .catch(() => setSaveError(true))
  }

  function close() {
    act.local.clearModal()
  }

  return <div className={classes("modal-container", {visible: open})}>
    <div className="modal edit-schedule">
      <div className="header">
        <h2>Schedule</h2>
        <a className="close js-close-modal" onClick={close}>X</a>
      </div>

      <div className='content'>
        { canSend && <h2 className='text-center'>You're going to schedule this message to { targetCount } people</h2> }
        { !canSend && <h2 className='text-center'>This message is for { targetCount } people</h2> }
        <Description searchId={searchId} center={true} />
        <div className='form'>
          <div className='field'>
            <label>Schedule for</label>
            <input
              type='datetime-local'
              onChange={handleChange}
              value={newScheduledFor}
              min={(new Date()).toISOString().substring(0, 16)}
            />
            <p className='dark-grey small smaller'>Choose your date and time locally; you don't need to worry about GMT or other time zones.</p>
          </div>
        </div>

        { insufficientCredit && <h4 className='text-center no-margin-top error'>You don't have enough credit to send this message. Please <a href='/admin/budget'>top up your account</a> and try again.</h4> }
        { !insufficientCredit && !canSend && <h4 className='text-center no-margin-top'>This message requires approval to send. If approved before the send time, it will send as scheduled. If approved after the send time it will send immediately. The approver can override your chosen send time.</h4> }

        { showValidation && validationErrors.length > 0 && <div>
          { validationErrors.map((error, i) => <p key={i} className='error'>{ error }</p>) }
        </div> }
        { canSend && <div className='buttons center margin-top margin-bottom double'>
          { scheduledFor && <ServerWaitButton onClick={handleUnscheduleClick} className='button red large margin-right'>Unschedule</ServerWaitButton> }
          <ServerWaitButton disabled={disabled} className={`button large primary ${disabled ? 'disabled' : ''}`} onClick={handleScheduleClick} >{ scheduledFor ? 'Reschedule' : 'Schedule' }</ServerWaitButton>
        </div> }
        { !canSend && <div className='buttons center margin-top margin-bottom double'>
          <ServerWaitButton disabled={disabled} className={`button large primary ${disabled ? 'disabled' : ''}`} onClick={handleSendForApprovalClick} >Send for approval</ServerWaitButton>
        </div> }
      </div>
    </div>
  </div>
}

