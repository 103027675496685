import { useAct } from 'FlowBuilder/store'

export default function RedirectActionForm({ actionId }) {
  const act = useAct()

  const action = useSel(s => s.actions.entities[s.local.selectedActionId])
  const config = action.config || { redirectUrl: '' }

  function update(data) {
    act.actions.upsert(actionId, { config: { ...config, ...data } })
  }

  return <div className="box narrow">
    <h2 className='no-margin-top'>Redirect</h2>
    <div className='field'>
      <label className="bold">URL to redirect to</label>
      <input type='text' className='full-width margin-top' value={config.redirectUrl} onChange={e => update({ redirectUrl: e.target.value })} />
    </div>
  </div>
}

