export default function flow(dis, store, client) {
  async function load(id) {
    return client.get(`/api/flows/${id}`)
      .then(({ data }) => {
        dis({ type: 'flow/upsert', payload: data })
      })
  }

  async function loadStats(id) {
    return client.get(`/api/flows/${id}/stats`)
      .then(({ data }) => {
        dis({ type: 'flow/upsert', payload: { stats: data } })
      })
  }

  async function close(id) {
    return client.post(`/api/flows/${id}/close`)
      .then(({ data }) => {
        dis({ type: 'flow/upsert', payload: data })
      })
  }

  return { load, loadStats, close }
}
